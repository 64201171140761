.main-block {
    background: url("../../assets/images/main-bg.png");
    background-size: cover;
    height: 650px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.main-block h1 {
    font-family: 'Poppins', sans-serif;
    font-size: 40px;
    color: white;
    max-width: 790px;
    text-align: center;
    caret-color: transparent;
    margin-top: 150px;
    padding: 0 10px;
    line-height: 80px; /* 137.931% */
    letter-spacing: 0.2px;
}

.main-block-btn {
    width: 216px;
    height: 54px;
    text-align: center;
    background-color: transparent;
    border: 3px solid white;
    border-radius: 6px;
    font-size: 20px;
    color: white;
    caret-color: transparent;
    margin-top: 62px;
}

@media screen and (min-width: 700px){
    .main-block h1 {
        font-size: 58px;
        margin-top: 280px;
    }
    .main-block {
        height: 752px;
    }

}
