.web-dev-wrapper {
  height: 792px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.50) 100%);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
}

@media screen and (max-width: 1000px) {
    .web-dev-wrapper {
        height: 692px;
    }
}

@media screen and (max-width: 900px) {
    .web-dev-wrapper {
        height: 592px;
    }
}

@media screen and (max-width: 600px) {
    .web-dev-wrapper {
        height: 482px;
    }
}

.web-dev-main-block{
    max-width: 569px;
    text-align: left;
}
.web-dev-main-block h2{
    text-align: left;
}

.why-dartlab-box{
    background-color: #161616;
}