.faq-text-box {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    letter-spacing: 0.2px;
    margin-bottom: 65px;
}

.faq-text-red {
    font-size: 20px;
    margin-bottom: 20px;
}

.faq-text-black {
    font-size: 16px;
    line-height: 26px;
    font-family: 'Poppins', sans-serif;
}

.faq-contact-box {
    padding: 40px 62px;
    border-radius: 12px;
    background: #2A2A2A;
    box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.08);
    margin-bottom: 46px;
}

@media screen and (max-width: 600px) {
    .faq-contact-box {
        padding: 30px 32px;
    }
}

.faq-contact-text-normal {
    color: #FFF;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px; /* 160% */
    letter-spacing: 0.2px;
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 900px) {
    .faq-contact-text-normal {
        margin-bottom: 20px;
    }
}

.faq-contact-text-bold {
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    font-size: 20px;
    font-weight: 500;
    line-height: 32px; /* 160% */
    letter-spacing: 0.2px;
    margin-bottom: 10px;
}