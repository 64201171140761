.gettik-info {
    height: 705px;
    overflow: visible;
    position: relative;
    padding-top: 152px;
    display: flex;
    background: linear-gradient(77deg, #24D07A 0%, #002B0E 100%);
    color: white;
    font-family: 'Poppins', sans-serif;
}

.gettik-title-info {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 0.2px;
    margin: 0;
}

.country-name {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 3.2px;
    margin-top: 10px;
    margin-left: 134px;
}

.available-on {
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.gettik-info-box {
    display: flex;
    align-items: center;
}

.gettik-info-box p {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.gettik-info-box img {
    margin-right: 20px;
}

.gettik-screens-img {
    /*width: 100%;*/
    position: absolute;
    top: -16%;
    right: 0;
}



@media screen and (max-width: 1280px) {

    .gettik-screens-img {
        width: 88%;
        position: absolute;
        top: -4%;
        right: 0;
    }
}


@media screen and (max-width: 1000px) {
    .gettik-info {
        height: 600px;
    }
}


@media screen and (max-width: 900px) {

    .gettik-screens-img {
        width: 90%;
        position: absolute;
        top: -3%;
        right: 0;
    }
}


@media screen and (max-width: 840px) {
    .available-on {
        font-size: 32px;
    }

    .gettik-info-box p {
        font-size: 32px;
    }

}


@media screen and (max-width: 780px) {
    .gettik-info {
        height: 520px;
        padding-top: 102px;
    }

    .gettik-screens-img {
        width: 88%;
        position: absolute;
        top: -4%;
        right: 0;
    }

    .available-on {
        font-size: 30px;
    }

    .gettik-info-box p {
        font-size: 30px;
    }

}

@media screen and (max-width: 600px) {
    .gettik-info-title-wrapper {
        margin-top: 30px
    }
}

@media screen and (max-width: 520px) {
    .gettik-info-title-wrapper {
        margin-top: 30px
    }

    .gettik-title-info{
        font-size: 54px;
    }

    .country-name {
        margin-left: 54px;
    }

    .gettik-info-title-wrapper img{
        width: 30%;
    }

    .available-on{
        font-size: 28px;
    }

    .gettik-info-box p {
        font-size: 26px;
    }
}

@media screen and (max-width: 440px) {

    .available-on{
        font-size: 22px;
    }

    .gettik-info-box p {
        font-size: 22px;
    }
}