.choose-us-block {
    padding: 112px 0 72px;
    background-color: #161616;
    font-family: 'Poppins', sans-serif;
}

.grey-small-text {
    color: #797979;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
    margin-top: 0;
}

.choose-us-title {
    color: white;
    font-size: 60px;
    font-style: normal;
    font-weight: 600;
    line-height: 70px;
    letter-spacing: 0.2px;
    max-width: 766px;
    margin-bottom: -40px;
}

.choose-black-box {
    background-color: black;
    border-radius: 6px;
    padding: 24px
}

.choose-grey-box {
    border-radius: 6px;
    background: #222;
    padding: 24px;
    height: 100%;
}

.choose-reason-title {
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2px;
    margin-bottom: 14px;
    color: white;
}

.reason-2 {
    font-size: 50px;
    color: #DD0510;
    line-height: 60px;
}

.reason-1 {
    font-size: 40px;
    line-height: 50px;
}

.reason-3 {
    font-size: 50px;
    line-height: 60px;
}

.reason-4 {
    font-size: 40px;
    line-height: 50px;
}

@media screen and (max-width: 1100px) {
    .choose-us-title {
        font-size: 50px;
        max-width: 766px;
        margin-bottom: -40px;
    }
}

@media screen and (max-width: 920px) {
    .choose-us-title {
        font-size: 50px;
        max-width: 566px;
        margin-bottom: -90px;
    }
}

@media screen and (max-width: 870px) {
    .choose-us-title {
        font-size: 45px;
        max-width: 466px;
        line-height: 60px;
        margin-bottom: -100px;
    }

    .reason-2 {
        font-size: 40px;
        color: #DD0510;
        line-height: 50px;
    }

    .reason-1 {
        font-size: 36px;
        line-height: 46px;
    }

    .reason-3 {
        font-size: 44px;
        line-height: 50px;
    }

    .reason-4 {
        font-size: 30px;
        line-height: 44px;
    }
}


@media screen and (max-width: 780px) {
    .choose-us-title {
        font-size: 42px;
        max-width: 466px;
        line-height: 60px;
        margin-bottom: -149px;
    }
}

@media screen and (max-width: 730px) {
    .choose-us-title {
        font-size: 45px;
        max-width: 580px;
        line-height: 50px;
        margin-bottom: 52px;
    }
    .choose-black-box {
        background-color: black;
        border-radius: 6px;
        padding: 24px;
        margin-bottom: 32px;
    }

    .choose-grey-box {
        border-radius: 6px;
        background: #222;
        padding: 24px;
        margin-bottom: 32px;
    }

    .choose-us-block {
        padding: 52px 0;
    }

}

@media screen and (max-width: 500px) {
    .choose-us-title {
        font-size: 36px;
        max-width: 590px;
        line-height: 45px;
        margin-bottom: 52px;
    }
}


