.timeline-box {
    background: #2A2A2A;
    padding: 30px 0;
}


.timeline-parent {
    position: relative;
}

.timeline-img {
    position: relative;
    width: 100%;
    height: auto;
    display: block;
}

.timeline-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.timeline-title {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 111.111% */
    letter-spacing: 0.2px;
}

.timeline-text {
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.2px;
}

.dashed-border {
    border-left: 4px dashed #949494;
}


.dashed-line {
    width: 100%;
    height: 1px;
    border-top: 1px dashed #949494;
}


.circle-timeline {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: white;
    position: absolute;
    top: 45%;
    left: -10px;
}

.circle {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: white;
}

.circle-1 {
    display: none;
    position: absolute;
    top: 45%;
    left: 0;
}

.circle-2 {
    position: absolute;
    top: 45%;
    left: 16.5%;
}

.circle-3 {
    position: absolute;
    top: 45%;
    left: 33%;
}

.circle-4 {
    position: absolute;
    top: 45%;
    left: 49.5%;
}

.circle-5 {
    position: absolute;
    top: 45%;
    left: 66.5%;
}

.circle-6 {
    position: absolute;
    top: 45%;
    left: 83.5%;
}

.circle-7 {
    display: none;
    position: absolute;
    top: 45%;
    right: -10px;
}


.timeline-1 {
    position: absolute;
    top: 28%;
    left: 14%;
    z-index: 33;
}

.timeline-2 {
    position: absolute;
    top: 28%;
    left: 29%;
    z-index: 33;
}

.timeline-3 {
    position: absolute;
    top: 33%;
    left: 44%;
    z-index: 33;

}

.timeline-4 {
    position: absolute;
    top: 24%;
    left: 63%;
    z-index: 33;

}

.timeline-5 {
    position: absolute;
    top: 30%;
    left: 78%;
    z-index: 33;

}


@media screen and (max-width: 1100px) {
    .timeline-title {
        font-size: 25px;
        line-height: 30px; /* 111.111% */
    }

    .timeline-text {
        font-size: 17px;
        line-height: 22px; /* 122.222% */
    }
}

@media screen and (max-width: 900px) {
    .timeline-title {
        font-size: 20px;
        line-height: 30px; /* 111.111% */
    }

    .timeline-text {
        font-size: 14px;
        line-height: 22px; /* 122.222% */
    }
}

/*.timeline-parent-mobile img{*/
/*    width: 100%;*/
/*    height: auto;*/
/*    display: block;*/
/*}*/


.timeline-wrapper-mobile {
    display: flex;
    justify-content: center;
    flex-direction: column;
    color: white;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    margin-bottom: 32px;
    position: relative;
    padding-left: 40px;
}


.timeline-parent-mobile {
    position: relative;
    min-height: 170px;
    display: flex;
    align-items: center;
}

.timeline-box-mobile {
    background: #2A2A2A;
    padding: 30px 0;
}

.timeline-wrapper-mobile h3 {
    font-size: 19px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 111.111% */
    letter-spacing: 0.2px;
}

.timeline-wrapper-mobile p {
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px; /* 122.222% */
    letter-spacing: 0.2px;
}

.timeline-mobile-1 {
    position: absolute;
    top: 3%;
    left: 6%;
    z-index: 33;
}

.timeline-mobile-2 {
    position: absolute;
    top: 61%;
    left: 23.5%;
    z-index: 33;
}


.timeline-mobile-3 {
    position: absolute;
    top: 3%;
    left: 37%;
    z-index: 33;
}

.timeline-mobile-4 {
    position: absolute;
    top: 61%;
    left: 58%;
    z-index: 33;
}

.timeline-mobile-5 {
    position: absolute;
    top: 3%;
    left: 69%;
    z-index: 33;
}

.car-icon {
    position: absolute;
    top: 36%;
    left: -8px;
    width: 30px;
    height: auto;
}

.goal-icon {
    position: absolute;
    top: 41%;
    right: -11px;
    width: 27px;
    height: auto;
}


@media screen and (max-width: 640px) {
    .timeline-mobile-1 {
        position: absolute;
        top: 3%;
        left: 4%;
        z-index: 33;
    }

    .timeline-mobile-2 {
        position: absolute;
        top: 61%;
        left: 21.5%;
        z-index: 33;
    }


    .timeline-mobile-3 {
        position: absolute;
        top: 3%;
        left: 35%;
        z-index: 33;
    }

    .timeline-mobile-4 {
        position: absolute;
        top: 61%;
        left: 56%;
        z-index: 33;
    }

    .timeline-mobile-5 {
        position: absolute;
        top: 3%;
        left: 67%;
        z-index: 33;
    }

    .goal-icon {
        position: absolute;
        top: 41%;
        right: -1px;
        width: 27px;
        height: auto;
    }
}


@media screen and (max-width: 520px) {
    .timeline-mobile-1 {
        position: absolute;
        top: 3%;
        left: 2%;
        z-index: 33;
    }

    .timeline-mobile-2 {
        position: absolute;
        top: 61%;
        left: 19.5%;
        z-index: 33;
    }


    .timeline-mobile-3 {
        position: absolute;
        top: 3%;
        left: 33%;
        z-index: 33;
    }

    .timeline-mobile-4 {
        position: absolute;
        top: 61%;
        left: 54%;
        z-index: 33;
    }

    .timeline-mobile-5 {
        position: absolute;
        top: 3%;
        left: 65%;
        z-index: 33;
    }

    .timeline-wrapper-mobile h3 {
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px; /* 111.111% */
        letter-spacing: 0.2px;
    }

    .timeline-wrapper-mobile p {
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 25px; /* 122.222% */
        letter-spacing: 0.2px;
    }
}


@media screen and (max-width: 480px) {
    .timeline-mobile-1 {
        position: absolute;
        top: 3%;
        left: 1%;
        z-index: 33;
    }

    .timeline-mobile-2 {
        position: absolute;
        top: 61%;
        left: 17.5%;
        z-index: 33;
    }


    .timeline-mobile-3 {
        position: absolute;
        top: 3%;
        left: 31%;
        z-index: 33;
    }

    .timeline-mobile-4 {
        position: absolute;
        top: 61%;
        left: 52%;
        z-index: 33;
    }

    .timeline-mobile-5 {
        position: absolute;
        top: 3%;
        left: 63%;
        z-index: 33;
    }
}


.timeline-small-screen {
    position: relative;
   min-height: 500px;
    padding: 62px 0;
}

.timeline-small-screen-box {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    color: white;
    font-family: 'Poppins', sans-serif;
    align-items: center;
}

.timeline-img-small {
    width: 105%;
    height: auto;
    display: block;
    position: absolute;
    top: 51%;
    left: -2%;
    transform: rotate(90deg);
}

.timeline-box-small {
    background: #2A2A2A;
    padding: 20px 0 45px;
}

.timeline-title-small {
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px; /* 111.111% */
    letter-spacing: 0.2px;
}

.timeline-text-small {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.2px;
}

.car-icon-small {
    position: absolute;
    top: 7%;
    left: 47%;
    width: 30px;
    height: auto;
}

.goal-icon-small {
    position: absolute;
    top: 94.5%;
    left: 46.8%;
    width: 30px;
    height: auto;
}

.circle-small-1 {
    position: absolute;
    top: 20%;
    left: 49.2%;
}

.circle-small-2 {
    position: absolute;
    top: 36%;
    left: 49.2%;
}

.circle-small-3 {
    position: absolute;
    top: 52%;
    left: 49.2%;
}

.circle-small-4 {
    position: absolute;
    top: 67%;
    left: 49.2%;
}

.circle-small-5 {
    position: absolute;
    top: 83%;
    left: 49%;
}

.timeline-small-1{
    position: absolute;
    top: 18%;
    left: 20%;
}

.timeline-small-2{
    position: absolute;
    top: 34%;
    left: 60%;
}

.timeline-small-3{
    position: absolute;
    top: 48%;
    left: 11%;
}

.timeline-small-4{
    position: absolute;
    top: 64%;
    left: 60%;
}

.timeline-small-5{
    position: absolute;
    top: 79%;
    left: 8%;
}
