.oshbus-feature-1{
    background-color: white;
    padding: 32px 0;
    font-family: 'Poppins', sans-serif;
    color: #161616;
}

.oshbus-main-feature-title{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 83.333% */
    letter-spacing: 0.2px;
    text-align: center;
    margin-bottom: 58px;
}

.oshbus-feature-title{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 166.667% */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.oshbus-feature-box-1 p{
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

}

.oshbus-feature-box-1 span{
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.oshbus-feature-img{
    margin-left: 20px;
    width: 100%;
    height: auto;
    max-width: 470px;
}

.oshbus-feature-img-wrapper {
    height: 100%;
}


.oshbus-feature-2-img{
    width: 100%;
    height: auto;
    max-width: 280px;
}

.oshbus-feature-3-img{
    width: 100%;
    height: auto;
    max-width: 320px;
}

.oshbus-feature-4-img{
    width: 100%;
    height: auto;
    max-width: 250px;
}

.oshbus-feature-2-img-wrapper{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px){
    .oshbus-main-feature-title{
        font-size: 42px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 58px;
    }
    .oshbus-feature-title{
        font-size: 22px;
        margin-bottom: 20px;
    }

    .oshbus-feature-box-1 p{
        font-size: 18px;
    }

    .oshbus-feature-box-1 span{
        font-size: 18px;
    }

    .oshbus-feature-img{
        margin-left: 0;
    }

    .oshbus-feature-2-img{
        max-width: 240px;
    }

}

@media screen and (max-width: 960px) {
    .oshbus-feature-2-img{
        max-width: 240px;
    }
}

@media screen and (max-width: 900px){
    .oshbus-main-feature-title{
        font-size: 42px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 58px;
    }
    .oshbus-feature-title{
        font-size: 22px;
        margin-bottom: 20px;
    }

    .oshbus-feature-box-1 p{
        font-size: 18px;
    }

    .oshbus-feature-box-1 span{
        font-size: 18px;
    }

    .oshbus-feature-img{
        margin-left: 0;
    }

}



@media screen and (max-width: 860px) {
    .oshbus-feature-2-img{
        max-width: 200px;
    }
}

@media screen and (max-width: 730px){
    .oshbus-feature-img{
        max-width: 350px;
        width: 100%;
        height: auto;
    }

    .oshbus-feature-img-wrapper{
        display: flex;
        justify-content: center;
    }

    .oshbus-feature-1{
        padding: 22px 0;
    }

    .oshbus-main-feature-title{
        font-size: 38px;
        margin-bottom: 38px;
    }
}


@media screen and (max-width: 600px){

    .oshbus-main-feature-title{
        font-size: 34px;
        margin-bottom: 38px;
    }
}