*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /*list-style-type: none;*/
}

body{
  font-family: 'Poppins', sans-serif;
  margin: 0;
  padding: 0;
  color: #161616;
  height: 100%;
  width: 100%;
}

#root{
  overflow: auto;
}


.styled-button{
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #FFFFFF;
  padding: 8px 22px;
  background: #DD0510;
  border-radius: 6px;
  outline: none;
  border: none;
  caret-color: transparent;
  cursor: pointer;
}


.just-gradient{
  background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.changeable-box-title{

}

