.cycle-content-title{
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px;
    letter-spacing: 0.2px;
    margin-bottom: 14px;
}

.cycle-content-text{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.cycle-content-box{
    margin-bottom: 48px;
}

@media screen and (max-width: 700px) {
    .cycle-content-title{
        font-size: 32px;
        line-height: 35px;
    }

    .cycle-content-text{
        font-size: 18px;
        line-height: 30px;
    }
}


@media screen and (max-width: 500px) {
    .cycle-content-title{
        font-size: 28px;
        line-height: 30px;
    }

    .cycle-content-text{
        font-size: 17px;
        line-height: 30px;
    }
}