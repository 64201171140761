.elmart-case {
    height: 730px;
    background-color: black;
    position: relative;
    font-family: 'Poppins', sans-serif;
}

.elmart-case-main {
    height: 815px;
    padding-top: 85px;
    background-color: black;
    overflow: hidden;
}

.elmart-case-title{
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.2px;
    background: linear-gradient(90deg, #BEE0EE 0%, #F8B5FF 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    display: inline-block;
}

.elmart-case span{
    color: white !important;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    letter-spacing: 0.2px;
}


.ellipse1 {
    width: 33%;
    height: auto;
    position: absolute;
    top: -20px;
    left: 0;
}

.ellipse2 {
    width: 22%;
    height: auto;
    position: absolute;
    bottom: -90px;
    right: 0;
}

.business {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 282px;
    transform: rotate(-9deg);
    position: absolute;
    bottom: 25%;
    left: 9%;
}

.business img {
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 50px;
    margin-bottom: 8px;
}

.business p {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 61.538% */
    letter-spacing: 0.2px;
}

.business-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 20px;
    border-radius: 14px;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
}

.business-2{
    transform: rotate(9deg);
    position: absolute;
    bottom: 24%;
    left: 73%;
}

.elmart-case-inner{
    text-align: center;
    margin-top: 140px;
}

.elmart-case-inner p{
    color: white;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: 0.2px;
    max-width: 575px;
    margin: 0 auto 24px;
}

.gradient-arrow-wrapper{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 24px 0 48px;
}

.estimate-btn{
    border-radius: 6px;
    border: 2px solid #DD0510;
    padding: 12px 32px;
    background-color: transparent;
    color: #DD0510;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 600;
}

.business-box-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 60px;
}

.business-small img{
    width: 45px;
    height: 45px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 8px;
}

.business-wrapper-small{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 8px 20px;
    border-radius: 14px;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
}

.business-wrapper-small p{
    color: #000;
font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px; /* 61.538% */
    letter-spacing: 0.2px;
    margin-bottom: 0;
}

.gradient-arrow-wrapper-small{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 1330px){
    .elmart-case {
        height: 680px;
    }

    .elmart-case-main {
        height: 775px;
        padding-top: 85px;
    }

    .ellipse1 {
        width: 32%;
        top: -20px;
        left: 0;
    }

    .ellipse2 {
        width: 23%;
        bottom: -90px;
        right: 0;
    }

    .business {
        position: absolute;
        bottom: 25%;
        left: 4%;
        width: 242px;

    }
    .business-2{
        transform: rotate(9deg);
        position: absolute;
        bottom: 24%;
        left: 76%;
    }

    .business p {
        font-size: 40px;
        line-height: 32px; /* 61.538% */
    }
}



@media screen and (max-width: 1170px){

    .ellipse1 {
        width: 38%;
        top: -20px;
        left: 0;
    }

    .ellipse2 {
        width: 24%;
        bottom: -90px;
        right: 0;
    }

    .business {
        position: absolute;
        bottom: 17%;
        left: 2%;
        width: 242px;

    }
    .business-2{
        transform: rotate(9deg);
        position: absolute;
        bottom: 16%;
        left: 77%;
    }

}

@media screen and (max-width: 1070px){

    .ellipse1 {
        width: 45%;
        top: -20px;
        left: 0;
    }

    .ellipse2 {
        width: 24%;
        bottom: -90px;
        right: 0;
    }

    .business {
        position: absolute;
        bottom: 17%;
        left: 2%;
        width: 202px;
    }

    .business-2{
        transform: rotate(9deg);
        position: absolute;
        bottom: 16%;
        left: 77%;
    }

    .business p {
        font-size: 34px;
        line-height: 32px; /* 61.538% */
    }

    .elmart-case-inner{
        text-align: center;
        margin-top: 100px;
    }

}

@media screen and (max-width: 900px){
    .gradient-arrow-wrapper{
        margin: 24px 0 28px;
    }
    .elmart-case-title{
        font-size: 72px;
    }

    .elmart-case span{
        font-size: 72px;
    }

    .elmart-case-inner{
        margin-top:80px;
    }
    .business-2{
        transform: rotate(9deg);
        position: absolute;
        bottom: 16%;
        left: 73%;
    }
    .ellipse1 {
        width: 52%;
        top: -20px;
        left: 0;
    }

    .ellipse2 {
        width: 27%;
        bottom: -90px;
        right: 0;
    }
}

@media screen and (max-width: 830px) {
    .gradient-arrow-wrapper {
        margin: 24px 0 28px;
    }

    .business-2{
        transform: rotate(9deg);
        position: absolute;
        bottom: 16%;
        left: 72%;
    }
}

@media screen and (max-width: 760px) {
    .gradient-arrow-wrapper {
        margin: 24px 0 28px;
    }
    .ellipse1 {
        width: 62%;
        top: -20px;
        left: 0;
    }

    .ellipse2 {
        width: 30%;
        bottom: -90px;
        right: 0;
    }

    .business {
        position: absolute;
        bottom: 11%;
        left: 2%;
        width: 202px;
    }

    .business-2{
        transform: rotate(9deg);
        position: absolute;
        bottom: 10%;
        left: 68%;
    }
}

@media screen and (max-width: 660px) {

    .ellipse1, .ellipse2 {
       display: none;
    }

    .elmart-case-title{
        font-size: 58px;
    }

    .elmart-case span{
        font-size: 58px;
    }

    .elmart-case-inner{
        margin-top:70px;
    }

    .elmart-case {
        height: 650px;
    }

    .elmart-case-main {
        height: 705px;
        padding-top: 85px;
    }

    .elmart-case-inner p{
        font-size: 20px;
        max-width: 475px;
    }
}

@media screen and (max-width: 510px) {
    .elmart-case-title{
        font-size: 50px;
    }

    .elmart-case span{
        font-size: 50px;
    }


}

@media screen and (max-width: 440px) {
    .elmart-case-title{
        font-size: 46px;
    }

    .elmart-case span{
        font-size: 46px;
    }

}

.changeable-box-elmart-title{
    color: #0645A3;
    font-family: 'Poppins', sans-serif;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 61.538% */
    letter-spacing: 0.2px;
    margin-bottom: 10px;
}