.swiper-button-next-my{
    color:chartreuse;
}

.single-slider-nav-btn{
    position: absolute;
    bottom:10px;
    right: 20px;
    z-index: 22;
}

.single-slider-nav-btn button{
    background-color: transparent;
    outline: none;
    border: none;
    cursor: pointer;
}

.single-slider-wrapper{
    position: relative;
    width: 100%;
    border-radius: 10px;
    margin-bottom: 28px;
    height: 460px;
}

@media screen and (min-width:600px){
    .single-slider-wrapper{
        width: 100%;
        max-width: 370px;
        height: 400px;
        /*height:100%;*/
    }
}


@media screen and (min-width:900px){
    .single-slider-wrapper{
        width: 100%;
        max-width: 370px;
        height: 660px;
        /*height:100%;*/
    }
}