.block-title {
    font-size: 42px;
    font-weight: 700;
    color: #6E6E73;
    margin: 28px 0 40px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.mission-box {
    background-color: #161616;
    padding: 0 15px 0 25px;
    font-family: 'Poppins', sans-serif;
    /*margin-bottom: 22px;*/
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    width: 100%;
}

.gradient {
    font-weight: 700;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 42px;
    margin-bottom: 22px;
    text-align: center;
    caret-color: transparent;

}
.mission-box p {
    color: white;
    width: 95%;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 24px;
    line-height: 30px;
    font-size: 20px;
}

.white-title{
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 38px;
    color: white;
    line-height: 57px;
    text-align: center;
    margin-bottom: 22px;
}


.white-text{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
}



@media screen and (max-width: 600px) {
    .white-title{
        font-size: 28px;
        line-height: 40px;
    }

    .white-text{
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 20px;
    }
}


@media screen and (max-width:960px) {
    .white-text{
        margin-bottom: 30px;
    }
}

@media screen and (min-width: 500px){
    .gradient{
        font-size: 36px;
        margin-top: 55px;
        margin-bottom: 32px;
        letter-spacing: 0.2px;
    }

    .mission-box p{
        font-size: 28px;
        line-height: 42px;
    }
    .mission-box {
        padding: 0 42px;
    }
}

.content-gradient {
    margin-top: 22px;
    margin-bottom: 20px;
}


.mission-box img {
    margin-bottom: 24px;
}


@media screen and (min-width: 885px) {


    .mission-box p {
        color: white;
        font-size: 28px;
        width: 378px;
        font-weight: 500;
        font-family: 'Poppins', sans-serif;
        margin-bottom: 24px;
        line-height: 42px;
    }

    .mission-box img {
        margin-bottom: 24px;
    }
}


.statistic {
    background-color: #F5F5F7;
    padding-top: 32px;
}

/*.statistic-box {*/
/*    background-color: white;*/
/*    font-family: 'Poppins', sans-serif;*/
/*    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);*/
/*    border-radius: 12px;*/
/*    height:100%;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    flex-direction: column;*/
/*    overflow: hidden;*/
/*    padding: 30px 10px;*/
/*    align-self: stretch;*/
/*}*/

.statistic-number {
    font-size: 25px;
    font-weight: 700;
}

.statistic-text {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 0 10px;
    line-height: 22px;

}



@media screen and (min-width: 450px) {
    .statistic-number {
        font-size: 40px;
    }

    .statistic-text {
        font-size: 20px;
        line-height: 32px;
        padding: 0 10px;
    }
}

@media screen and (min-width: 600px) {


    .statistic-number {
        font-size: 60px;
    }

    .statistic-text {
        font-size: 24px;
        line-height: 32px;
        padding: 0 10px;
    }
}



@media screen and (min-width: 700px) {
    .statistic-number {
        font-size: 80px;
    }

    .statistic-text {
        font-size: 30px;
        line-height: 32px;
    }
}
@media screen and (min-width: 900px) {
    .statistic-number {
        font-size: 90px;
    }

    .statistic-text {
        font-size: 30px;
        line-height: 32px;
        padding: 0 33px;
    }
}




@media screen and (min-width: 1200px) {
    .statistic-number {
        font-size: 102px;
        margin-top: -45px;
    }
}


.offer {
    background-color: #F5F5F7;
    margin-bottom: 16px;
}


.offer-second-box, .offer-first-box {
    height: 752px;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.offer-first-box {
    background: url("../assets/images/offer-bg-1.png") no-repeat;
    background-position-y: 200px;
    background-position-x: 30px;
    background-size: 560px;
    background-color: white;
}

.offer-second-box {
    background: url("../assets/images/offer-bg.jpg");
    background-size: cover;
    background-position: center;
}

.dark-bg {
    background: url("../assets/images/dark-bg.png");
    background-size: cover;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.offer-first-box h3,
.offer-second-box h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-weight: 700;
    margin: 63px 0 22px;
    padding: 0 10px;
}

.offer-first-box p,
.offer-second-box p {
    line-height: 30px;
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    max-width: 399px;
    margin-bottom: 32px;
    padding: 0 10px;
}

.offer-first-box h3 {
    color: #161616;
}

.offer-second-box h3,
.offer-second-box p {
    color: white;
}

.expertise-icon-wrapper{
    width: 18px;
    height: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
}

.expertise-icon-wrapper img{
    width: 75%;
    height: auto;
}

.expertise-box-parent{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly ;
}


.expertise-p-box {
    padding: 0 5px;
}
.box1, .box3{
    margin-left: 0px;
    padding-left: 10px;
    width: 52%;
}

.box2, .box4{
    margin-left: 0px;
    padding-left: 10px;
    width: 42%;
}



.read-more-btn {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    background-color: transparent;
    border: none;
    color: #2997FF;
    caret-color: transparent;
    font-weight: 600;
    line-height: 30px;
    cursor: pointer;
}

.expertise-block {
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 10px;

}

.video-container {
    min-height: 370px;
    width: 100%;
    position: relative;
    height: auto;
}

.video-container video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.content {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    object-fit: fill;
}

.expertise-block-text {
    margin-bottom: 32px;
    text-align: center;
    padding: 0 10px;
}



.content p {
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    color: white;
}
.expertise-row{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}


#expertise-box p {
    display: flex;
    font-family: 'Poppins', sans-serif;
    align-items: center;
    text-align: center;
  font-size: 12px;
}

#expertise-box p img {
    margin-right: 12px;
}


@media screen and (min-width: 374px){
    .expertise-box-parent{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly ;
    }


    .expertise-p-box {
        padding: 0 5px;
    }
    .box1, .box3{
        margin-left: 0px;
        padding-left: 10px;
        width: 52%;
    }

    .box2, .box4{
        margin-left: 0px;
        padding-left: 10px;
        width: 42%;
    }
    #expertise-box p{
        font-size: 13px;
    }

}


@media screen and (min-width: 400px){
    .expertise-box-parent{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around ;
    }


    .expertise-p-box {
        padding: 0 5px;
    }
    .box1, .box3{
        margin-left: 0px;
        padding-left: 20px;
        width: 51%;
    }

    .box2, .box4{
        margin-left: 0px;
        padding-left: 1px;
        width: 42%;
    }

}


@media screen and (min-width: 450px){
    .expertise-p-box {
        padding: 0 5px;
        width: 42%;
    }

    .box1, .box3{
        margin-left: 30px;
        padding-left: 0;
        width: 42%;
    }

    .box2, .box4{
        margin-left: 20px;
        padding-left: 0;
        width: 42%;
    }
    .expertise-box-parent{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-evenly ;
    }

}



@media screen and (min-width: 490px){
    .box1, .box3{
        margin-left: 40px;
    }

    .box2, .box4{
        margin-left: 10px;
    }
    .expertise-box-parent{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between ;
    }
    #expertise-box p{
        font-size: 14px;
    }
}


@media screen and (min-width: 512px){
    .expertise-icon-wrapper{
        width: 18px;
        height: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }

    .expertise-icon-wrapper img{
        width: 90%;
        height: auto;
    }

    .box1, .box3{
        margin-left: 30px;
        width: 40%;
    }

    .box2, .box4{
        margin-left: 20px;
        width: 40%;
    }
    .expertise-box-parent{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around ;
    }
  #expertise-box p{
      font-size: 15px;
    }

    .expertise-p-box {
        padding: 0 5px;
        width: 40%;
    }
    .video-container {
        min-height: 424px;
        width: 100%;
        position: relative;
        height: auto;
    }
    .expertise-row{
        display: flex;
        align-items: center;
        margin-bottom: 10px;
    }
}




@media screen and (min-width: 600px){
    .box1, .box3{
        margin-left: 50px;
    }

    .box2, .box4{
        margin-left: 50px;
    }


}


@media screen and (min-width: 420px) {
    .content p {
        font-family: 'Poppins', sans-serif;
        font-size: 14px;
        color: white;
    }
}

@media screen and (min-width: 700px) {
    .content p {
        font-family: 'Poppins', sans-serif;
        font-size: 18px;
        color: white;
    }

    .expertise-block-text {
        margin-bottom: 32px;
        text-align: center;
    }

    #expertise-box p{
        font-size: 16px;
    }
}

@media screen and (min-width: 900px) {

    #expertise-box p{
        font-family: 'Poppins', sans-serif;
        font-size: 20px
    }
}

@media screen and (min-width: 900px) {

    .expertise-box-parent{
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
    }

    .expertise-p-box {
        padding: 0 5px;
        width: auto;
    }


    .box1, .box3{
        margin-left: 0;
    }

    .box2, .box4{
        margin-left: 0;
    }
    .expertise-icon-wrapper{
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 12px;
    }

    .expertise-icon-wrapper img{
        width: 95%;
        height: auto;
    }

    .expertise-block-text {
        margin-bottom: 62px;
        text-align: center;
    }

    .content-gradient {
        margin-top: 50px;
        margin-bottom: 16px;
    }


}

.services {
    width: 100%;
    overflow-x: hidden;
    margin-top: 25px;
}

.web {
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 562px;
}

.web h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 38px;
    color: white;
    line-height: 57px;
    text-align: center;
}

.web-img {
    max-width: 679px;
    width: 100%;
    height: auto;
    align-self: center;
    margin-bottom: 32px;
    padding: 0 15px;

}

.ux {
    background-color: white;
    min-height: 562px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.ux-img {
    max-width: 788px;
    width: 100%;
    height: auto;
}

.ux h2 {
    font-size: 38px;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: 150px;

}

.ux-mobile {
    background-color: white;
    min-height: 562px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.bigger-gradient {
    font-size: 28px;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    margin-top: 32px;
}

.biggest-gradient {
    font-size: 32px;
    line-height: 40px; /* 112.903% */
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
}

.margin-bottom-42{
    margin-bottom: 42px;
}

.margin-bottom-52{
    margin-bottom: 52px;
}

.gradient-title-wrapper{
    display: flex;
    justify-content: center;
}

@media screen and (min-width: 600px) {
    .bigger-gradient {
        font-size: 32px;
        line-height: 42px;
    }
    .biggest-gradient{
        font-size: 42px;
        line-height: 50px;
    }
}

@media screen and (min-width: 1100px) {
    .bigger-gradient {
        font-size: 38px;
        line-height: 48px;
    }
    .biggest-gradient{
        font-size: 62px;
        line-height: 70px;
    }
}


.ux-img-mob {
    max-width: 562px;
    width: 100%;
    height: auto;
}

.mobile {
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 562px;
}

.mobile h2 {
    font-family: 'Poppins', sans-serif;
    font-weight: 700;
    font-size: 38px;
    color: white;
    line-height: 57px;
    text-align: center;
    padding-top: 26px;
    margin-bottom: 10px;
}


.mobile-img {
    max-width: 585px;
    width: 100%;
    height: auto;
}

.mobile-dev-pic {
    margin-top: -20px;
}

.case-transport {
    background-color: white;
    padding: 30px 42px;
    margin-bottom: 62px;
}

.case-transport-slider {
    margin-bottom: 62px;
    background-color: transparent;
    padding-bottom: 10px;
    min-height: 460px;
}

.case-market-slider {
    min-height: 500px;
    background-color: white;
    padding-bottom: 10px;
}

.case-transport-text {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    margin: 42px 0 46px 0;
    z-index: 22;
    position: relative;
}

.tracking-img-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: none;
}
.tracking-img-wrapper img {
    display: none;
}

@media screen and (min-width: 400px){
    .case-transport-slider {
        min-height: 500px;
    }
    .case-market-slider{
        min-height: 550px;
    }

}

@media screen and (min-width: 440px){
    .case-transport-slider {
        min-height: 550px;
    }
    .case-market-slider{
        min-height: 570px;
    }
}

@media screen and (min-width: 470px){
    .case-transport-slider {
        min-height: 600px;
    }
    .case-market-slider{
        min-height: 620px;
    }
}
@media screen and (min-width: 520px){
    .case-transport-slider {
        min-height: 680px;
    }
    .case-market-slider{
        min-height: 680px;
    }
}

@media screen and (min-width: 550px){
    .case-market-slider{
        min-height: 700px;
    }
}

@media screen and (min-width: 590px){
    .case-transport-slider {
        min-height: 730px;
    }
    .case-market-slider{
        min-height: 730px;
    }
}
@media screen and (min-width: 600px){

    .case-market-slider{
        min-height: 680px;
    }
}


@media screen and (min-width: 650px){
    .case-market-slider{
        min-height: 730px;
    }
}


@media screen and (min-width: 701px){
    .case-transport-slider {
        min-height: 650px;
    }
    .case-market-slider{
        min-height: 650px;
    }

}
@media screen and (min-width: 760px){

    .case-market-slider{
        min-height: 710px;
    }
}

@media screen and (min-width: 780px){
    .case-transport-slider {
        min-height: 700px;
    }

}
@media screen and (min-width: 820px){
    .case-market-slider {
        min-height: 760px;
    }
}

@media screen and (min-width: 840px){
    .case-transport-slider {
        min-height: 760px;
    }
}


@media screen and (min-width: 1000px) {
    .tracking-img-wrapper {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .tracking-img-wrapper img {

    }

}



/*@media screen and (min-width: 900px) {*/
/*    .tracking-img-wrapper img{*/
/*        position: absolute;*/
/*        top: -144px;*/
/*        right: -338px;*/
/*    }*/
/*}*/

@media screen and (min-width: 1000px) {
    .tracking-img-wrapper img {
        position: absolute;
        top: -140px;
        right: -358px;
        display: flex;
    }
}


@media screen and (min-width: 1062px) {
    .tracking-img-wrapper img {
        position: absolute;
        top: -157px;
        right: -338px;
    }
}

@media screen and (min-width: 1100px) {
    .tracking-img-wrapper img {
        position: absolute;
        top: -167px;
        right: -338px;
    }
}

@media screen and (min-width: 1128px) {
    .tracking-img-wrapper img {
        position: absolute;
        top: -183px;
        right: -338px;
    }
}


@media screen and (min-width: 1275px) {
    .tracking-img-wrapper img {
        position: absolute;
        top: -239px;
        right: -338px;
    }
}

.tech-stack {
    background-color: #161616;
    min-height: 666px;
    padding: 52px 0 32px;
}
.detailed-tech-stack{
    background-color: #2A2A2A;
    min-height: 496px;
}

.tech-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

@media screen and (min-width: 900px){
    .tech-tabs {
        justify-content: left;
    }
}

.tech-tabs-button {
    height: 36px;
    padding: 8px 24px;
    background-color: #E8E8ED;
    border-radius: 980px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 13px;
    outline: none;
    border: none;
    margin-top: 10px;
    font-size: 15px;
}

.tech-tabs button {
    height: 36px;
    padding: 8px 24px;
    background-color: #E8E8ED;
    border-radius: 980px;
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
    border: none;
    margin-top: 10px;
    font-size: 15px;
}

.tech-tabs button img,
.tech-tabs-button img
{
    margin-right: 5px;
    width: 15px;
}

.stack-parent {
    display: flex;
    flex-wrap: wrap;
    margin: 62px 6px;
    justify-content: center;
}

.stack-child {
    background-color: #1D1F23;
    border: 1px solid black;
    box-shadow: 0px 4px 40px rgba(9, 9, 12, 0.1);
    width: 102px;
    height: 103px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.active {
    background-color: #3B3D41;
}

.stack-child p {
    color: #6E6E73;
    font-family: 'Poppins', sans-serif;
    font-size: 12px;
    line-height: 30px;
}

.clents-text {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #161616;
    line-height: 26px;
    max-width: 772px;
    margin: 0 auto 40px;
    text-align: center;
}

.logo-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 60px;
    min-width: 260px;
    height: 100%;
    width: 100%;
}

.logo-wrapper img{
    max-width: 282px;
    height: auto;
}


.case-social {
    /*min-height: 800px;*/
    background-color: #161616;
    padding-top: 1px;
    width: 100%;
    display: flex;
    flex-direction: column;
}

.case-social-text {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    text-align: center;
    margin-top: -22px;
    padding:  0 10px;
}

.case-social-description {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    line-height: 26px;
    color: white;
    text-align: center;
    max-width: 1032px;
    margin: 50px auto 32px;
}

.case-social-back{
    background-color: #161616;
    min-height: 430px;
    margin: -1px 0;
}


@media screen and (min-width: 400px){
    .case-social-back{
        min-height: 460px;
    }
}

@media screen and (min-width: 440px){
    .case-social-back{
        min-height: 520px;
    }
}

@media screen and (min-width: 490px){
    .case-social-back{
        min-height: 550px;
    }
}
@media screen and (min-width: 520px){
    .case-social-back{
        min-height: 620px;
    }
}

@media screen and (min-width: 570px){
    .case-social-back{
        min-height: 660px;
    }
}

@media screen and (min-width: 600px){
    .case-social-back{
        min-height: 600px;
    }
}

@media screen and (min-width: 640px){
    .case-social-back{
        min-height: 650px;
    }
}

@media screen and (min-width: 700px){
    .case-social-back{
        min-height: 550px;
    }
}

@media screen and (min-width: 730px){
    .case-social-back{
        min-height: 610px;
    }
}

@media screen and (min-width: 815px){
    .case-social-back{
        min-height: 650px;
    }
}



.screen-animation-box {
    display: flex;
    position: relative;
    margin-top: 62px;
    justify-content: center; /* Added */
    height: 90px;
}

.screen-box {
    max-width: 240px;
    height: 488px;
    margin: 0 10px;
    box-shadow: -150px 150px 250px rgba(0, 0, 0, 0.4);
    overflow: hidden;
    border-radius: 50px;
    transition: transform 3s ease;
    will-change: transform;
}
.case-social-bottom{
    background-color: #161616;
    padding: 20px 0 10px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
}


.case-social-bottom-box {
    margin-top: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 10px 50px;
}

@media screen and (min-width: 850px) {

    .screen-animation-box {
        display: flex;
        position: relative;
        margin-top: 62px;
        justify-content: center; /* Added */
        height: 90px;
        max-width: 820px;
    }


    .case-social-description {
        margin-bottom: 60px;
        margin-top: 50px;
    }


    .case-social-bottom-box {
        margin-top: auto;
    }

    .screen-box {
        max-width: 140px;
        height: auto;
        margin: 0 10px;
        border-radius: 10px;
    }

    .screen-1 {
        position: absolute;
        top: 90px;
        left: 0px;
        transition: top 1s ease;
    }

    .screen-2 {
        position: absolute;
        top: 0;
        left: 162px;
        transition: top 1s ease;
    }

    .screen-3 {
        position: absolute;
        top: 90px;
        left: 325px;
        transition: top 1s ease;
    }

    .screen-4 {
        position: absolute;
        top: 0;
        left: 488px;
        transition: top 1s ease;
    }

    .screen-5 {
        position: absolute;
        top: 90px;
        right: 0px;
        transition: top 1s ease;
    }


}
@media screen and (min-width: 1100px) {
    .case-social-description {
        margin-bottom: 60px;
        margin-top: -5px;
    }
}

@media screen and (min-width: 872px) {
    .case-social {
        min-height: 680px;
    }
    .screen-animation-box {
        display: flex;
        position: relative;
        margin: 62px auto 0;
        justify-content: center; /* Added */
        height: 90px;
        max-width: 820px;
    }

    .screen-box {
        max-width: 150px;
        height: auto;
        margin: 0 10px;
        border-radius: 10px;
    }

}

@media screen and (min-width: 1090px) {
    .case-social {
        min-height: 780px;
    }
    .screen-animation-box {
        display: flex;
        position: relative;
        margin: 62px auto 0;
        justify-content: center; /* Added */
        height: 90px;
        max-width: 950px;
    }

    .screen-box {
        max-width: 170px;
        height: auto;
        margin: 0 10px;
        border-radius: 10px;
    }

    .screen-1 {
        position: absolute;
        top: 90px;
        left: 0px;
        transition: top 1s ease;
    }

    .screen-2 {
        position: absolute;
        top: 0;
        left: 190px;
        transition: top 1s ease;
    }

    .screen-3 {
        position: absolute;
        top: 90px;
        left: 380px;
        transition: top 1s ease;
    }

    .screen-4 {
        position: absolute;
        top: 0;
        left: 570px;
        transition: top 1s ease;
    }

    .screen-5 {
        position: absolute;
        top: 90px;
        right: 0px;
        transition: top 1s ease;
    }


}


@media screen and (min-width: 1220px) {
    .case-social {
        min-height: 880px;
    }

    .screen-animation-box {
        display: flex;
        position: relative;
        margin: 62px auto 0;
        justify-content: center; /* Added */
        height: 90px;
        max-width: 1150px;
    }

    .screen-box {
        max-width: 200px;
        height: auto;
        margin: 0 10px;
        border-radius: 10px;
    }

    .screen-1 {
        position: absolute;
        top: 90px;
        left: 0px;
        transition: top 1s ease;
    }

    .screen-2 {
        position: absolute;
        top: 0;
        left: 233px;
        transition: top 1s ease;
    }

    .screen-3 {
        position: absolute;
        top: 90px;
        left: 468px;
        transition: top 1s ease;
    }

    .screen-4 {
        position: absolute;
        top: 0;
        left: 700px;
        transition: top 1s ease;
    }

    .screen-5 {
        position: absolute;
        top: 90px;
        right: 0px;
        transition: top 1s ease;
    }

}


@media screen and (min-width: 1398px) {


    .screen-animation-box {
        display: flex;
        position: relative;
        margin: 62px auto 0;
        justify-content: center; /* Added */
        height: 90px;
        max-width: 100%;
    }

    .screen-box {
        max-width: 240px;
        height: 488px;
        margin: 0 10px;
        border-radius: 50px;
    }


    .screen-1 {
        position: absolute;
        top: 90px;
        left: -60px;
        transition: top 1s ease;
    }

    .screen-2 {
        position: absolute;
        top: 0;
        left: 215px;
        transition: top 1s ease;
    }

    .screen-3 {
        position: absolute;
        top: 90px;
        left: 493px;
        transition: top 1s ease;
    }

    .screen-4 {
        position: absolute;
        top: 0;
        left: 770px;
        transition: top 1s ease;
    }

    .screen-5 {
        position: absolute;
        top: 90px;
        right: -70px;
        transition: top 1s ease;
    }

    /*.animate .screen-1 {*/
    /*    transform: translate(0, -90px);*/
    /*}*/

    /*.animate .screen-2 {*/
    /*    transform: translate(0, 90px);*/
    /*}*/

    /*.animate .screen-3 {*/
    /*    transform: translate(0, -90px);*/
    /*}*/

    /*.animate .screen-4 {*/
    /*    transform: translate(0, 90px);*/
    /*}*/

    /*.animate .screen-5 {*/
    /*    transform: translate(0, -90px);*/
    /*}*/

}

.screen-box img {
    display: block;
    width: 100%;
    height: auto;
}

.phase-box {
    width: 375px;
    height: 499px;
    border-radius: 12px;
    filter: drop-shadow(2px 4px 12px rgba(0, 0, 0, 0.08));

    padding: 32px 22px;
    box-sizing: border-box;
}

.phase1 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 44.35%, rgba(0, 0, 0, 0) 100%), url(../assets/images/phase1.png);
    background-size: cover;
}

.phase2 {
    background-color: #2A2A2A;
    background-image: url(../assets/images/phase2.png);
    background-size: 235px;
    background-position: 69px 260px;
    background-repeat: no-repeat;
}

.phase3 {
    background-color: #161616;
    background-image: url(../assets/images/phase3.png);
    background-size: 339px;
    background-position: 22px 260px;
    background-repeat: no-repeat;
}

.phase4 {
    background-color: white;
    background-image: url(../assets/images/phase4.png);
    background-size: 339px;
    background-position: 22px 260px;
    background-repeat: no-repeat;
}

.phase5 {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.6) 44.35%, rgba(0, 0, 0, 0) 100%), url(../assets/images/phase5.jpg);
    background-size: cover;
}

.phase-number {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 22px;
}


.phase-title {
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 12px;
}

.phase-text {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
}

.white {
    color: white;
}

.light-grey {
    color: #848484;
}

.red {
    color: #DD0510;
}

.case-market{
    margin-bottom: 62px;
    padding-top: 30px;
}

.case-market-inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 200px;
    position: relative;
    background-color: white;

}

.bg-market {
    display: none;
}

.case-market-inner p {
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    color: #161616;
    line-height: 26px;
    max-width: 566px;
    text-align: center;
    margin: 12px 10px 16px;
}

.market-content {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media screen and (min-width: 900px) {
    .case-market-inner {
        min-height: 420px;
        background-image: url("../assets/images/market.png");
        background-size: cover;
    }
    .case-market-inner p {
        font-size: 16px;
        color: #161616;
        margin: 5px 10px 5px 40px;
    }
}

@media screen and (min-width: 900px) {
    .case-market-inner {
        min-height: 420px;
        background-image: url("../assets/images/market.png");
        background-size: cover;
    }
    .case-market-inner p {
        font-size: 16px;
        color: #161616;
        margin: 5px 10px 5px 80px;
    }
}


@media screen and (min-width: 950px) {
    .case-market-inner {
        min-height: 450px;
    }
    .case-market-inner p {
        font-size: 18px;
        color: #161616;
        margin: 12px 10px 16px 60px;
    }
}


@media screen and (min-width: 1150px) {
    .case-market-inner {
        min-height: 500px;
    }
    .case-market-inner p {
        font-size: 18px;
        color: #161616;
        margin: 12px 10px 16px 10px;
    }
}

@media screen and (min-width: 1200px) {

    .case-market-inner {
        min-height: 540px;
    }
}

@media screen and (min-width: 1260px) {
    .market-content {
        margin: 0;
    }

    .case-market-inner {
        min-height: 560px;
    }
}


.testimonials{
    background-color: #161616;
    padding: 1px 0 102px 0;
    margin-bottom: 62px;
}

.contact-block{
    padding-bottom: 62px;
}

.contact-box{
    background-color: white;
    padding: 1px 30px 35px;
    border-radius: 12px;
    /*margin-bottom: 62px;*/
}

@media screen and (min-width: 600px){
    .contact-box{
        background-color: white;
        padding: 1px 102px 52px;
        /*margin-bottom: 62px;*/
    }
}

.contact-input{
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    width: 100%;
    min-height: 50px;
    padding: 0 20px;
    margin-bottom: 28px;
    font-family: 'Poppins', sans-serif;
    color: #161616;
    font-size: 16px;
}

.contact-input.bolder::placeholder{
    font-weight: 600;
}


.contact-input-textarea{
    background: #F9F9F9;
    border: 1px solid #DDDDDD;
    border-radius: 4px;
    resize: none;
    padding: 10px;
    width: 100%;
    min-height: 162px;
    font-family: 'Poppins', sans-serif;
    color: #161616;
    box-sizing: border-box;
    font-size: 16px;
}

.contact-input-textarea:focus{
    border: 1px solid #161616;
    outline: none;
}

.contact-input-textarea::placeholder{
    font-size: 16px;
    color: #848484;
    font-family: 'Poppins', sans-serif;
}

.contact-input:focus {
    border: 1px solid #161616;
    outline: none;
}

.contact-input::placeholder {
    font-size: 16px;
    font-weight: 400;
    color: #848484;
    font-family:'Poppins', sans-serif;
}

input[type="checkbox"]{
    visibility: hidden;
    margin-left: -17px;
}

.error{
    border: 1px solid #DD0510;
}

.agree-label{
    font-family: 'Poppins', sans-serif;
    color: #161616;
    font-weight: 500;
    font-size: 16px;
    margin-left: 10px;
}

.send-btn{
    font-family: 'Poppins', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 30px;
    color: #FFFFFF;
    padding: 8px 22px;
    background: #DD0510;
    border-radius: 6px;
    outline: none;
    border: none;
    caret-color: transparent;
    margin-top: 40px;
}

.margin-top{
    margin-top: 85px;
}

.company-main-block{
    background-color: #2A2A2A;
    min-height: 647px;
}

.company-main-inner{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 560px;
}

.company-main-inner h2{
    margin-bottom: 20px;
    margin-top: 80px;
}
.company-main-inner p{
    font-family: 'Poppins', sans-serif;
    color: #FFF;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    max-width:982px
}


.dark-text{
    color:  #161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.company-testimonials{
    background-color: #161616;
    padding-bottom: 62px;
}


@media screen and (max-width: 600px) {
    .company-main-inner p{
        font-size: 18px;
        line-height: 28px;
    }

    .dark-text{
        font-size: 18px;
        line-height: 28px;
    }
}

.change-block-wrapper{
    padding: 57px 0 15px;
}

.get-consultation-contact-text{
    color: #000;
   font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px; /* 231.25% */
    letter-spacing: 0.2px;
    margin: 0;
}