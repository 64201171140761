.elmart-play-market{
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    height: 880px;
    padding: 62px 0;
    font-family: 'Poppins', sans-serif;
}

.elmart-play-market h2{
   color: white;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 60px */
    text-align: center;
}

.elmart-play-inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.elmart-img{
    width: 90%;
    height: auto;
    margin-bottom: 20px;
    margin-top: 10px;
}

.elmart-store-wrapper{
    color: white;
    font-family: 'Poppins', sans-serif;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}

.elmart-logo-wrapper img{
    margin-right: 10px;
}

.elmart-logo-wrapper{
    display: flex;
    align-items: center;
    margin-bottom: 14px;
}

.elmart-logo-wrapper p{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 24px */
}

.elmart-store-text{
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 36px */
    text-align: left;
}
.elmart-available-on{
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 36px */
    margin-bottom: 8px;
    text-align: left;
}


@media screen and (max-width: 1200px){
    .elmart-play-market{
        height: 830px;
    }
}

@media screen and (max-width: 1100px){
    .elmart-play-market{
        height: 800px;
    }
}

@media screen and (max-width: 950px){
    .elmart-play-market{
        height: 700px;
        padding: 62px 0;
    }

    .elmart-play-market h2{
        font-size: 52px;
    }
}

@media screen and (max-width: 850px){
    .elmart-play-market{
        height: 660px;
        padding: 62px 0;
    }

    .elmart-play-market h2{
        font-size: 48px;
    }
}

@media screen and (max-width: 820px){
    .elmart-play-market h2{
        font-size: 44px;
    }
    .elmart-store-text{
        font-size: 30px;
    }

    .elmart-available-on{
        font-size: 30px;
    }

    .elmart-play-market{
        height: 620px;
        padding: 62px 0;
    }
}

@media screen and (max-width: 650px){
    .elmart-play-market{
        height: 600px;
        padding: 62px 0;
    }
}

@media screen and (max-width: 620px){
    .elmart-play-market{
        height: 580px;
        padding: 62px 0;
    }
}

@media screen and (max-width: 560px){
    .elmart-play-market{
        height: 550px;
        padding: 62px 0;
    }
}

@media screen and (max-width: 480px){
    .elmart-play-market{
        height: 530px;
        padding: 62px 0;
    }
}

@media screen and (max-width: 450px){
    .elmart-play-market{
        height: 470px;
        padding: 42px 0;
    }

    .elmart-store-text{
        font-size: 23px;
    }

    .elmart-available-on{
        font-size: 23px;
    }
}

@media screen and (max-width: 450px){
    .elmart-play-market{
        height: 440px;
        padding: 42px 0;
    }

    .elmart-store-text{
        font-size: 23px;
    }

    .elmart-available-on{
        font-size: 23px;
    }
    .elmart-play-market h2{
        font-size: 34px;
    }
}