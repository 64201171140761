.phases-container{
    width: 100%;
    margin-top: 32px;
    height: 499px;
    display: flex;
    justify-content: center;

}
.phases{
    padding:20px 0 0;
}

.phases-container .my_swiper_container_phases .swiper-slide{
    display: flex;
    justify-content: center;
   width: 375px;
}

.phases-container .my_swiper_container_phases {
    width: 100%;
    max-width: 2000px; /* Adjust the value to match the desired container width */
    margin: 0 auto;
    padding: 0 0 0 16px;

}


@media screen and (min-width: 600px){
    .phases-container .my_swiper_container_phases {
        padding: 0 0 0 16px;
    }
}

@media screen and (min-width: 900px){
    .phases-container .my_swiper_container_phases {
        padding: 0 0 0 40px;
    }
}

@media screen and (min-width: 1000px){
    .phases-container .my_swiper_container_phases {
        padding: 0 0 0 60px;
    }
}

@media screen and (min-width: 1400px){
    .phases-container .my_swiper_container_phases {
        padding: 0 0 0 100px;
    }
}