.reason-white-box {
    background-color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reason-dark-box {
    background-color: #2A2A2A;
    display: flex;
    justify-content: center;
    align-items: center;
}

.reason-inner-box {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 462px;
    align-items: center;
    padding: 40px 0 ;
}


.reason-icon-wrapper {
    max-width: 270px;
    margin-right: 20px;
    flex-shrink: 0;
}

.reason-icon-wrapper img {
    width: 100%;
    height: auto;
}

.black-title {
    color: #161616;
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 126.316% */
    letter-spacing: 0.2px;
}

.reason-black-text {
    color: #161616;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
}

.reason-white-text{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
}

.text-wrapper {
    max-width: 661px;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

@media screen and (max-width: 800px) {
    .reason-inner-box {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto;
        width: 100%;
        flex-direction: column;
        height: 100%;
    }
    .reason-icon-wrapper {
        max-width: 100px;
        margin: 20px 20px 20px 0;
    }

    .reason-icon-wrapper img {
        width: 100%;
        height: auto;
    }
    .reason-black-text {
        font-size: 20px;
    }

    .reason-white-text{
        font-size: 20px;
    }
}