
.gettik-case {
    padding-top: 70px;
    background-color: #161616;
}

.gettik-case-main-block {
    height: 760px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gettik-case-main-block h2 {
    color: white;
    text-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25), 0px 10px 4px rgba(0, 0, 0, 0.35);
    font-family: 'Poppins', sans-serif;
    font-size: 90px;
    font-style: normal;
    font-weight: 700;
    line-height: 90px; /* 100% */
    letter-spacing: -0.8px;
    text-transform: uppercase;

}

.gettik-case-main-title-1 {
    max-width: 558px;
    position: relative;
    z-index: 2;
}

.gettik-case-main-title-2 {
    max-width: 465px;
    padding-left: 79px;
    margin-bottom: 100px;
    position: relative;
    z-index: 2;
}

.gettik-phone-img {
    position: absolute;
    top: 40%;
    left: 51.5%;
    transform: translate(-50%, -50%);
    z-index: 1;
    width: 270px;
    height: auto;
}

.gettik-case-estimate {
    max-width: 378px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.gettik-case-estimate p {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.gettik-case-estimate button {
    align-self: flex-end;
}




.gettik-timeline-block img{
}

.gettik-case-info-box{
    border-radius: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.gettik-case-info-box h3{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 83.333% */
    letter-spacing: 0.2px;
    margin-bottom: 14px;
}

.gettik-case-info-box p{
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%; /* 48px */
    letter-spacing: 0.2px;
}

.gettik-case-info-box-white {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #A6C0FE;
    background: white;
    height: 141px;
}

.gettik-case-info-box-dark {
    display: flex;
    padding: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #FFF;
    background: linear-gradient(0deg, rgba(80, 87, 96, 0.20) 0%, rgba(80, 87, 96, 0.20) 100%), #63636B;
    height: 141px;
}

.gettik-case-info-box-dark h3,
.gettik-case-info-box-white h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px; /* 125% */
    letter-spacing: 0.2px;
    margin-bottom: 8px;
}

.gettik-case-info-box-dark p,
.gettik-case-info-box-white p {
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 122.222% */
    letter-spacing: 0.2px;
}

.gettik-case-info-box-white p {
    color: #000000;

}

.gettik-case-info-box-dark p {
    color: white;
}

.gettik-case-info-box-white h3 {
    color: #000000;
}

.gettik-case-info-box-dark h3{
    color: white;
}

.gettik-changeable-box{
    padding: 62px 0 62px;
}

.gettik-changeable-box-title{
   font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 84.211% */
    letter-spacing: 0.2px;
    margin-bottom: 8px;
    background: linear-gradient(77deg, #24D07A 0%, #002B0E 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gettik-changeable-box-text{
    color: #252525;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.2px;
    margin-bottom: -60px;
}


@media screen and (max-width: 960px) {
    .gettik-changeable-box-text{
        margin-bottom: -10px;
    }

    .gettik-case-main-block {
        height: 660px;
    }

    .gettik-phone-img{
        display: none;
    }



}


@media screen and (max-width: 580px) {
    .gettik-case-main-block h2 {
        font-size: 65px;
        line-height: 65px; /* 100% */
    }

    .gettik-case-main-title-1 {
        max-width: 400px;
        position: relative;
        z-index: 2;
    }

    .gettik-case-main-title-2 {
        max-width: 465px;
        padding-left: 79px;
        margin-bottom: 100px;
        position: relative;
        z-index: 2;
    }
}

@media screen and (max-width: 580px) {

    .gettik-case-main-block {
        height: 520px;
        padding-top: 20px;
    }

    .gettik-case-main-block h2 {
        font-size: 55px;
        line-height: 55px; /* 100% */
    }

    .gettik-case-main-title-1 {
        max-width: 300px;
        position: relative;
        z-index: 2;
    }

    .gettik-case-main-title-2 {
        max-width: 465px;
        padding-left: 79px;
        margin-bottom: 100px;
        position: relative;
        z-index: 2;
    }
}

@media screen and (max-width: 580px) {

    .gettik-case-main-block h2 {
        font-size: 50px;
        line-height: 50px; /* 100% */
    }

    .gettik-case-main-title-1 {
        max-width: 270px;
        position: relative;
        z-index: 2;
    }

    .gettik-case-main-title-2 {
        max-width: 465px;
        padding-left: 79px;
        margin-bottom: 100px;
        position: relative;
        z-index: 2;
    }

    .play-market{
        max-width: 180px;
        height: auto;
    }
    .app-market{
        max-width: 153px;
    }
}

