.company-history-block{
    background-color: #fff;
    padding: 62px 0;
}
.company-history-block h2{
    margin-top: 0;
    margin-bottom: 22px;
text-align: center;
}


.company-history-block p{
    text-align: center;
}

