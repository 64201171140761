.elmart-feature-1{
    padding: 72px 0;
    background-color: #161616;
    font-family: 'Poppins', sans-serif;

}
.elmart-feature-2{
    padding: 72px 0;
    background-size: 55%;
    background-position: left 88%;
    background-repeat: no-repeat;
}

.elmart-feature-3{
    background-color:#2A2A2A;
}

.elmart-feature-1 h2{
    color: white;
    text-align: center;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 66.667% */
    letter-spacing: 0.2px;
    margin-bottom: 72px;
}

.elmart-feature-box-1{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.elmart-feature-title{
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 48px */
    letter-spacing: 0.2px;
    margin-bottom: 48px;
}

.elmart-feature-box-2{
    color: black;
    font-family: 'Poppins', sans-serif;
}



.elmart-feature-box-1 p,
.elmart-feature-box-2 p{
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.elmart-feature-box-1 span{
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.elmart-feature-img-wrapper{
    display: flex;
    justify-content: center;
}

.elmart-feature-img{
    margin-left: 30%;
}

.elmart-feature-4-img{
    width: 90%;
    height: auto;
}

.elmart-feature-2-img{
    margin-left: 12%;
}

.elmart-feature-2-img-wrapper{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 1280px){
    .elmart-feature-img{
        margin-left: 20%;
    }
}

@media screen and (max-width: 1200px){
    .elmart-feature-title{
        font-size: 42px;
        margin-bottom: 48px;
    }
}

@media screen and (max-width: 1036px){
    .elmart-feature-title{
        font-size: 40px;
        margin-bottom: 45px;
    }
    .elmart-feature-img{
        margin-left: 10%;
    }

    .elmart-feature-box-1 p,
    .elmart-feature-box-2 p,
    .elmart-feature-box-1 span {
        font-size: 22px;
    }
}

@media screen and (max-width: 960px){

    .elmart-feature-1 h2{
        font-size: 52px;
    }
    .elmart-feature-2{
        background-size: 75%;
        background-position: left 88%;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width: 860px){

    .elmart-feature-1 h2{
        font-size: 44px;
    }

    .elmart-feature-title{
        font-size: 32px;
        margin-bottom: 40px;
        line-height: 40px;
    }
}

@media screen and (max-width: 730px){
    .elmart-feature-img{
        margin-left: 0;
    }

    .elmart-feature-2-img{
        margin-left: 0;
    }

    .elmart-feature-1{
        padding: 62px 0 32px;
    }
    .elmart-feature-2{
        padding: 62px 0 32px;
    }

    .elmart-feature-1 h2{
        font-size: 44px;
        margin-bottom: 42px;
    }
    .elmart-feature-2{
        background-size: 100%;
        background-position: left 32%;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width: 540px){
    .elmart-feature-box-1 p,
    .elmart-feature-box-2 p,
    .elmart-feature-box-1 span {
        font-size: 20px;
    }
}