.team-augmentation-block {
    background-color: #161616;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 230px;
    padding-bottom: 30px;
}



.team-augmentation-block h2 {
    margin-bottom: 10px;
}

.team-augmentation-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 60px 0 34px;
}

.team-augmentation-img {
    width: 100%;
    height: auto;
    display: block;
    max-width: 462px;
}

@media screen and (max-width: 900px) {
    .team-augmentation-block{
        padding-top: 120px;
    }


}

@media screen and (max-width: 1100px) {
    .team-augmentation-img {
        width: 100%;
        height: auto;
        display: block;
        max-width: 262px;
    }
}