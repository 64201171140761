.statistic-box {
    background-color: white;
    font-family: 'Poppins', sans-serif;
    box-shadow: 2px 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    /*padding: 30px 10px;*/
    position: relative;
    transition: transform 0.6s;
    transform-style: preserve-3d;

}
.statistic-content {
    position: absolute;
    width: 100%;
    transform: translateY(-1%) ;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    top: 0;
    left: 0;
    padding: 0 10px;
}

.slide-enter {
    transform: translateY(-1%) ;
    animation: slide-in 3s forwards;
}

.slide-exit {
    animation: slide-out 3s forwards;
    transform: translateY(100%) ;
}

@keyframes slide-in {
    0% { transform: translateY(100%); opacity: 0; }
    50% { transform: translateY(-1%); opacity: 1; }
    100% { transform: translateY(-1%); opacity: 1; }
}

@keyframes slide-out {
    0% { transform: translateY(0%); opacity: 1; }
    50% { transform: translateY(-200%); opacity: 0; }
    100% { transform: translateY(-200%); opacity: 0; }
}

@media screen and (min-width: 600px){
    .statistic-box {
        height: 280px;
    }
    .statistic-content {
        padding: 0 20px;
    }

}

@media screen and (min-width: 800px){
    .statistic-box {
        height: 365px;
    }

}