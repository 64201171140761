


.get-consultation-inner-block {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 463px;
}

.get-consultation-title {
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 600;
    line-height: 48px; /* 110% */
    letter-spacing: 0.2px;
    margin-bottom: 36px;
}

.get-consultation-block p {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: 37px; /* 132.143% */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
    text-align: center;
}

.get-consultation-block button {
    font-family: 'Poppins', sans-serif;
}

@media screen and (max-width: 800px){
    .get-consultation-title {
        font-size: 32px;
        line-height: 40px; /* 110% */
    }

    .get-consultation-block p {
        font-size: 22px;
        line-height: 37px; /* 132.143% */
    }
}

@media screen and (max-width: 550px){
    .get-consultation-title {
        font-size: 28px;
        line-height: 38px; /* 110% */
    }

    .get-consultation-block p {
        font-size: 20px;
        line-height: 30px; /* 132.143% */
    }
}


