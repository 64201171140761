.team-mod{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 462px;
}

.team-box{
    height: 462px
}

.team-box h2{
    text-align: left;
}