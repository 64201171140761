.ux-step-img-wrapper{
    width: 100%;
    height: 100%;
}

.ux-step-img-wrapper img{
    width: 100%;
    height: auto;
    display: block;
}

.step-title{
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    margin-top: 22px;
}

@media screen and (max-width: 599px){
    .step-title{
        font-size: 18px;
    }
}

.step-box{
    max-width: fit-content;
    display: flex;
    align-items: center;
    flex-direction: column;
}