.company-culture{
    background-color: #161616;
    padding: 62px 0 0 0;
}

.culture-text-box{
    border-radius: 12px;
    background: #2A2A2A;
    padding: 62px 42px;
    min-height: 527px;
}

.culture-text-box h2{
    text-align: left;
}

.culture-pic-wrapper{
    min-height: 527px;
    border-radius: 12px;
    width: 100%;
    height: 100%;
}

.culture-approach-box{
    background-color: #FFFF;
    padding: 62px 42px;
    border-radius: 12px;
    min-height: 527px;
}


@media screen and (max-width:600px){
    .culture-text-box{
        padding: 32px 32px;
        min-height: 387px;
    }
    .culture-pic-wrapper{
        min-height: 387px;
        height: 100%;
    }
    .culture-approach-box{
        min-height: 387px;
        padding: 32px 32px;
    }
    .culture-approach-box h2{
        font-size: 28px;
    }
}




.culture-approach-box h2{
    margin-bottom: 22px;
    margin-top: 0;

}