.white-bg-block{
    background-color: #fff;
    padding: 62px 0 16px;
}

.benefit-text-box{
    margin-bottom: 33px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
}


@media screen and (max-width: 600px) {
    .benefit-text-box{
        font-size: 20px;
    }
}

.text-red{
    color: #DD0510;
    font-weight: 500;
}

.text-black{
    color: #161616;
    font-weight: 400;
}