.web-service-block{
    background-color: #161616;
    padding: 52px 0 0;
}

.web-service-inner{
    padding: 62px 85px 0;

}


.biggest-title{
    font-size: 32px;
    line-height: 40px; /* 112.903% */
    font-family: 'Poppins', sans-serif;
    color: #161616;
    font-style: normal;
    font-weight: 700;
    letter-spacing: 0.2px;
}


.black-text-web{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    color: #161616;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
}

.web-service-img-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: auto;
    height: 288px;
    margin-bottom: 5px;
}

@media screen and (max-width: 600px) {
    .web-service-inner{
        padding: 22px 16px 0;
    }

    .web-service-img-wrapper{
        height: 188px;
        margin-bottom: 5px;
    }

    .black-text-web{
            font-size: 18px;
            line-height: 28px;
            margin-bottom: 20px;
    }
}


@media screen and (min-width: 600px) {
    .biggest-title{
        font-size: 42px;
        line-height: 50px;
    }
}

@media screen and (min-width: 1100px) {
    .biggest-title{
        font-size: 62px;
        line-height: 70px;
    }
}

