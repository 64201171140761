.white-planning-block {
    padding: 62px 0 80px;
    background-color: white;
    color: black;
    font-family: 'Poppins', sans-serif;
    position: relative;
}


.white-planning-step-bar{
    width: 100%;
    margin: 40px 0 62px;
}


.white-planning-title{
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 88.889% */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.white-planning-text{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.2px;
}

.margin-bottom-72{
    margin-bottom: 72px;
}

.black-dots-list{
    list-style-type: disc;
    list-style-position: inside;
    color: black;
}

.black-dots-list li{
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 177.778% */
    letter-spacing: 0.2px;
}

@media screen and (max-width: 900px){
    .white-planning-title{
        font-size: 32px;
        line-height: 32px;
        margin-bottom: 17px;
    }

    .white-planning-text{
        font-size: 16px;
        line-height: 32px; /* 177.778% */
    }

    .black-dots-list li{
        font-size: 16px;
        line-height: 32px; /* 177.778% */
    }
    .white-planning-block {
        padding: 62px 0 62px;
    }
}


.vertical-gradient{
    width: 4px;
    height: auto;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    border-radius: 10px;
    margin-right: 20px;
    position: relative;
}

.gradient-circle{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
}

.gradient-circle-1{
    position: absolute;
    top: -2%;
    right: -188%;
}

.gradient-circle-2{
    position: absolute;
    top: 34%;
    right: -188%;
}

.gradient-circle-3{
    position: absolute;
    top: 64%;
    right: -188%;
}

@media screen and (max-width: 730px){
    .white-planning-title{
        font-size: 36px;
        line-height: 36px;
        margin-bottom: 17px;
    }

    .white-planning-text{
        font-size: 18px;
        line-height: 32px; /* 177.778% */
    }

    .black-dots-list li{
        font-size: 18px;
        line-height: 32px; /* 177.778% */
    }

    .white-planning-block{
        padding: 62px 0 40px;

    }
}

@media screen and (max-width: 540px){
    .white-planning-title{
        font-size: 34px;
        line-height: 36px;
        margin-bottom: 17px;
    }

    .white-planning-text{
        font-size: 16px;
        line-height: 32px; /* 177.778% */
    }

    .black-dots-list li{
        font-size: 16px;
        line-height: 32px; /* 177.778% */
    }
    .white-planning-block {
        padding: 42px 0 42px;
    }
}

@media screen and (max-width: 440px){
    .gradient-circle-1{
        position: absolute;
        top: -1%;
        right: -188%;
    }

    .gradient-circle-2{
        position: absolute;
        top: 32%;
        right: -188%;
    }

    .gradient-circle-3{
        position: absolute;
        top: 66%;
        right: -188%;
    }
}