.main-features-block {
    background-color: #FFFFFF;
}

.main-features-block-title {
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 40px; /* 64.516% */
    letter-spacing: 0.2px;
    text-align: center;
    align-self: flex-end;
}

.main-features-block-inner {
    min-height: 498px;
    display: flex;
    justify-content: center;
    padding-bottom: 124px;
    /*background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(239,239,239,1) 100%);*/
}

.main-features-dark-box {
    background-color: #161616;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    padding: 66px 0;
    min-height: 400px;
    display: flex;
    align-items: center;
}

.main-features-dark-box-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 83.333% */
    letter-spacing: 0.2px;
    margin-bottom: 48px;
}

.main-features-dark-box-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.gettik-feature-screen {
    position: absolute;
    top: -132px;
    right: -12px;
}


.gettik-feature-screen2 {
    position: absolute;
    top: -102px;
    left: -18px;
}

.gettik-feature-screen3 {
    width: 67%;
    position: absolute;
    top: -169px;
    right: -12px;
}

.gettik-feature-screen4 {
    /*width: 67%;*/
    position: absolute;
    top: -169px;
    right: -12px;
}

.gettik-map-marker-img {
    position: absolute;
    top: 245px;
    right: -51px;
}

.gettik-chat-img {
    width: 80%;
    position: absolute;
    top: 239px;
    right: -140px;
}

.gettik-credit-card {
    position: absolute;
    top: 105px;
    left: 42px;
}

.gettik-rating-img {
    position: absolute;
    top: -190px;
    right: -104px;
    z-index: 3;
}

.main-features-white-box {
    background-color: #FFFFFF;
    color: #000;
    font-family: 'Poppins', sans-serif;
    padding: 66px 0;
    height: 890px;
    display: flex;
    align-items: center;
}

.white-feature-box-2{
    background-color: #FFFFFF;
    color: #000;
    font-family: 'Poppins', sans-serif;
    padding: 66px 0;
    height: 890px;
    display: flex;
    align-items: center;
}

.main-features-white-box-title {
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 56px; /* 83.333% */
    letter-spacing: 0.2px;
    margin-bottom: 40px;
}

.high-title{
    margin-top: -20%;
}

.main-features-white-box-text {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


.gettik-feature-screen-small {
    max-width: 360px;
    height: auto;
    display: block;
    margin: 30px auto 0;
}

.gettik-map-marker-img-small {
    position: absolute;
    top: 61%;
    right: 16%;
}


.gettik-feature-screen2-small{
    max-width: 360px;
    height: auto;
    display: block;
    margin: 30px auto 0;
}

.gettik-chat-img-small {
    position: absolute;
    top: 73%;
    left: 7%;
    transform: scaleX(-1);
    width: 36%;
}

.gettik-feature-screen3-small{
    max-width: 240px;
    height: auto;
    display: block;
    margin: 30px auto 0;
}

.gettik-credit-card-small{
    position: absolute;
    top: 53%;
    right: 20%;
}

.gettik-feature-screen4-small{
    max-width: 440px;
    height: auto;
    display: block;
    margin: 30px auto 0;
}

.gettik-rating-img-small{
    position: absolute;
    top: 2%;
    right: 16%;
    width: 30%;
}


@media screen and (max-width: 1280px) {
    .main-features-block-inner {
        min-height: 223px;
        display: flex;
        justify-content: center;
        padding-bottom: 124px;
    }

    .main-features-block-title {
        color: #000;
        font-family: 'Poppins', sans-serif;
        font-size: 52px;
        line-height: 40px; /* 64.516% */
    }

    .gettik-feature-screen {
        position: absolute;
        top: -132px;
        right: -12px;
        width: 80%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 245px;
        right: -1px;
    }

    .main-features-dark-box-title {
        font-size: 42px;
        margin-bottom: 48px;
    }

    .main-features-white-box-title {
        font-size: 42px;
        margin-bottom: 42px;
    }

    .gettik-feature-screen2 {
        position: absolute;
        top: -102px;
        left: 18px;
        width: 80%;
    }

    .gettik-chat-img {
        width: 56%;
        position: absolute;
        top: 279px;
        right: -4%;
    }

    .main-features-white-box {
        padding: 66px 0;
        height: 780px;
    }

    .gettik-feature-screen3{
        width: 53%;
        position: absolute;
        top: -147px;
        right: 6%;
    }

    .gettik-credit-card {
        position: absolute;
        top: 152px;
        left: 30%;
    }

    .gettik-feature-screen4 {
        position: absolute;
        top: -151px;
        right: 16%;
        width: 80%;
    }

    .gettik-rating-img {
        position: absolute;
        top: -148px;
        right: 6%;
        z-index: 3;
        width: 43%;
    }
}


@media screen and (max-width: 1120px) {
    .main-features-block-inner {
        min-height: 223px;
        display: flex;
        justify-content: center;
        padding-bottom: 124px;
    }

    .main-features-block-title {
        color: #000;
        font-family: 'Poppins', sans-serif;
        font-size: 52px;
        line-height: 40px; /* 64.516% */
    }

    .gettik-feature-screen {
        position: absolute;
        top: -122px;
        right: -9px;
        width: 77%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 245px;
        right: -1px;
    }

    .main-features-dark-box-title {
        font-size: 42px;
        margin-bottom: 48px;
    }

    .gettik-feature-screen3{
        width: 57%;
        position: absolute;
        top: -143px;
        right: 7%;
    }

    .gettik-credit-card {
        position: absolute;
        top: 186px;
        left: 18%;
    }



}

@media screen and (max-width: 1080px) {
    .main-features-block-inner {
        min-height: 223px;
        display: flex;
        justify-content: center;
        padding-bottom: 124px;
    }

    .main-features-block-title {
        color: #000;
        font-family: 'Poppins', sans-serif;
        font-size: 52px;
        line-height: 40px; /* 64.516% */
    }

    .gettik-feature-screen {
        position: absolute;
        top: -106px;
        right: 40px;
        width: 77%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 245px;
        right: 20px;
    }

    .main-features-dark-box-title {
        font-size: 40px;
        margin-bottom: 38px;
    }

    .main-features-dark-box-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .main-features-white-box-title {
        font-size: 40px;
        margin-bottom: 38px;
    }

    .main-features-white-box-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .main-features-block-inner {
        min-height: 223px;
        padding-bottom: 94px;
    }

    .main-features-white-box {
        padding: 66px 0;
        height: 740px;
    }

    .gettik-feature-screen2 {
        position: absolute;
        top: -88px;
        left: 18px;
        width: 80%;
    }

    .gettik-chat-img {
        width: 56%;
        position: absolute;
        top: 270px;
        right: -4%;
    }

    .gettik-feature-screen3{
        width: 61%;
        position: absolute;
        top: -153px;
        right: 14%;
    }

    .gettik-credit-card {
        position: absolute;
        top: 148px;
        left: 7%;
    }



}


@media screen and (max-width: 980px) {

    .main-features-block-title {
        color: #000;
        font-family: 'Poppins', sans-serif;
        font-size: 52px;
        line-height: 40px; /* 64.516% */
    }

    .gettik-feature-screen {
        position: absolute;
        top: -107px;
        right: 29px;
        width: 83%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 225px;
        right: 20px;
        width: 35%;
    }

    .main-features-dark-box-title {
        font-size: 38px;
        margin-bottom: 38px;
    }

    .main-features-dark-box-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .main-features-white-box-title {
        font-size: 38px;
        margin-bottom: 38px;
    }

    .main-features-white-box-text {
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .main-features-white-box {
        padding: 66px 0;
        height: 700px;
    }

    .gettik-feature-screen2 {
        position: absolute;
        top: -58px;
        left: 8px;
        width: 85%;
    }

    .gettik-chat-img {
        width: 56%;
        position: absolute;
        top: 270px;
        right: -4%;
    }

    .gettik-feature-screen3{
        width: 62%;
        position: absolute;
        top: -132px;
        right: 6%;
    }

    .gettik-credit-card {
        position: absolute;
        top: 159px;
        left: 12%;
    }

}

@media screen and (max-width: 945px) {

    .main-features-block-title {
        color: #000;
        font-family: 'Poppins', sans-serif;
        font-size: 52px;
        line-height: 40px; /* 64.516% */
    }

    .gettik-feature-screen {
        position: absolute;
        top: -100px;
        right: 29px;
        width: 83%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 225px;
        right: 20px;
        width: 35%;
    }

    .main-features-dark-box-title {
        font-size: 34px;
        margin-bottom: 30px;
    }

    .main-features-dark-box-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }

    .main-features-white-box-title {
        font-size: 34px;
        margin-bottom: 30px;
    }

    .main-features-white-box-text {
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
    }
}


@media screen and (max-width: 915px) {

    .gettik-feature-screen {
        position: absolute;
        top: -100px;
        right: 17px;
        width: 88%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 225px;
        right: 20px;
        width: 35%;
    }

    .main-features-block-inner {
        padding-bottom: 94px;
    }

    .gettik-feature-screen2 {
        position: absolute;
        top: -58px;
        left: 8px;
        width: 89%;
    }

    .gettik-chat-img {
        width: 56%;
        position: absolute;
        top: 270px;
        right: -4%;
    }

    .main-features-white-box {
        height: 600px;
    }


    .gettik-feature-screen4 {
        position: absolute;
        top: -138px;
        right: 12%;
        width: 83%;
    }

    .gettik-rating-img {
        position: absolute;
        top: -148px;
        right: 6%;
        z-index: 3;
        width: 43%;
    }

}

@media screen and (max-width: 875px) {

    .gettik-feature-screen {
        position: absolute;
        top: -100px;
        right: 10px;
        width: 92%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 225px;
        right: 20px;
        width: 35%;
    }

    .gettik-feature-screen3{
        width: 66%;
        position: absolute;
        top: -128px;
        right: 6%;
    }

    .gettik-credit-card {
        position: absolute;
        top: 159px;
        left: 12%;
    }

    .gettik-feature-screen4 {
        position: absolute;
        top: -124px;
        right: 9%;
        width: 92%;
    }

    .gettik-rating-img {
        position: absolute;
        top: -148px;
        right: 6%;
        z-index: 3;
        width: 43%;
    }

}


@media screen and (max-width: 837px) {

    .gettik-feature-screen {
        position: absolute;
        top: -48px;
        right: 40px;
        width: 83%;
    }

    .gettik-map-marker-img {
        position: absolute;
        top: 225px;
        right: 1px;
        width: 35%;
    }

    .main-features-block-title {
        font-size: 42px;
        line-height: 40px; /* 64.516% */
    }

    .main-features-block-inner {
        min-height: 160px;
        padding-bottom: 64px;
        /*background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(239,239,239,1) 100%);*/
    }


    .gettik-feature-screen2 {
        position: absolute;
        top: 13px;
        left: 8px;
        width: 92%;
    }

    .gettik-chat-img {
        width: 56%;
        position: absolute;
        top: 270px;
        right: -4%;
    }

    .main-features-white-box {
        height: 590px;
        padding: 66px 0 80px;
    }

    .gettik-feature-screen4 {
        position: absolute;
        top: -102px;
        right: 9%;
        width: 92%;
    }

    .gettik-rating-img {
        position: absolute;
        top: -125px;
        right: 5%;
        z-index: 3;
        width: 41%;
    }

}


@media screen and (max-width: 822px) {

    .gettik-feature-screen {
        position: absolute;
        top: -18px;
        right: 40px;
        width: 83%;
    }

    .gettik-feature-screen2 {
        position: absolute;
        top: -1px;
        left: 8px;
        width: 92%;
    }

}


@media screen and (max-width: 730px) {

    .main-features-white-box {
       height: 895px;
        padding: 0 0 40px;
    }



}


@media screen and (max-width: 650px) {
    .main-features-block-title {
        font-size: 38px;
        line-height: 40px;
        margin: auto 0;
    }

    .main-features-block-inner {
        height: 160px;
        /*padding-bottom: 0;*/
    }
    .main-features-white-box {
        height: 935px;
        padding: 0 0 40px;
    }

}

@media screen and (max-width: 575px) {
    .main-features-block-title {
        font-size: 38px;
        line-height: 40px;
    }

    .main-features-white-box {
        height: 965px;
        padding: 0 0 40px;
    }

    .gettik-map-marker-img-small{
        display:none;
    }
    .gettik-chat-img-small {
        display:none;
    }

    .gettik-rating-img-small{
        display: none;
    }
    .white-feature-box-2{
        padding: 30px 0 40px;
    }

}

.main-features-block-inner-small {
    height: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width: 475px) {
    .main-features-white-box {
        height: 985px;
        padding: 20px 0 40px;
    }

    .white-feature-box-2{
        padding: 0;
        height: 885px;
    }



}



