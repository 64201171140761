.process-block {
    background-color: #161616;
    padding: 50px 0 10px;
}

.process-block h2 {
    margin-bottom: 10px;
}

.team-dark {
    padding: 62px 40px;
    background-color: #2A2A2A;
    border-radius: 12px;
    height: 100%;
}

.team-white {
    padding: 62px 40px;
    background-color: #FFFFFF;
    border-radius: 12px;
    height: 100%;
}

.team-dark p{
    margin-top: auto;
}

.team-white p {
    padding-left: 0;
    margin-top: auto;
}

.team-white h2 {
    margin-bottom: 22px;
    line-height: 47px;
    font-size: 31px;
}

@media screen and (min-width: 1100px) {
    .team-white h2 {
        margin-bottom: 22px;
        line-height: 47px;
        white-space: nowrap;
        font-size: 38px;
    }
}

.arrow-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0;
}

.process-pic-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 62px;
    padding: 0 20px;
}

.process-pic-wrapper img {
    width: 100%;
    height: auto;
    display: block;
}

.arrow-icon-wrapper img {
    max-width: 80px;
    width: 100%;
    height: auto;
    display: block;

}

@media screen and (max-width: 900px) {
    .arrow-icon-wrapper img {
        max-width: 84px;
        width: 100%;
        height: auto;
        display: block;
    }

}

@media screen and (min-width: 1100px) {
    .arrow-icon-wrapper img {
        max-width: 134px;
        width: 100%;
        height: auto;
        display: block;
    }

}