.navigation-btn{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: white;
    font-weight: 400;
    background-color: transparent;
    border: none;
    cursor: pointer;
}

.navigation-btn:hover{
    color: #DD0510;
}

.navigation-btn_active{
    color: #DD0510;
}
@media screen and (min-width: 1032px){
    .navigation-btn{
        font-size: 18px;
    }
}


.contact-us{
    width: 146px;
    height: 46px;
    background-color: #DD0510;
    color: white;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    font-size: 18px;
    border-radius: 6px;
    border: 1px solid #DD0510;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dark{
    background-color: #161616;
}
.transparent {
    background-color: transparent;
}

.dropdown-title{
    color: #848484;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.dropdown-link{
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-decoration: none;
    display: block;
}

.dropdown-link:hover{
    color: #DD0510;
}

.dropdown-mobile{
    padding: 7px 0;
}
