.card {
    color: white;
    padding: 20px 20px 0;
    max-width: 1030px;
    height: 220px;
    position: relative;
}

.testimon-name {
    font-size: 18px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    margin: 50px 0 6px;

}
.mobile-testimon-text{
    padding: 20px;
    background-color: black;
    color: #363434;
    font-size: 15px;
    font-family: 'Poppins', sans-serif;
    display: block;
}

.testimonials .swiper-slide-active .mobile-testimon-text{
    color: white;
    transition: opacity 1s ease;
}

.testimonial-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    font-family: 'Poppins', sans-serif;
    display: none;
}


@media screen and (min-width: 400px){
    .card {
        padding: 30px 30px 0;
        max-width: 1030px;
        height: 280px;
    }
}


@media screen and (min-width: 600px){
    .card {
        padding: 30px 30px 0;
        max-width: 1030px;
        height: 350px;
    }

}

@media screen and (min-width: 800px){
    .card {
        padding: 30px 30px 0;
        max-width: 1030px;
        height: 400px;
    }
    .mobile-testimon-text{
        display: none ;
    }

    .testimonial-text {
        font-weight: 400;
        font-size: 18px;
        line-height: 26px;
        font-family: 'Poppins', sans-serif;
        display: block;
    }


}
@media screen and (min-width: 1000px){
    .card {
        padding: 82px 102px 0;
        max-width: 1030px;
        height: 470px;
    }


}
.testimonial-type {
    background-color: #ECFDF5;
    border-radius: 4px;
    max-width: 220px;
    height: 26px;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    align-items: center;
    color: #161616;
    font-weight: 600;
    font-size: 12px;
    font-family: 'Poppins', sans-serif;
    margin: 20px 0 20px;
}

@media screen and (min-width: 900px){
    .testimonial-type {
        margin: 30px 0 30px;
    }
}

@media screen and (min-width: 1000px){
    .testimonial-type {
        margin: 36px 0 64px;
    }
}



.testimonial-type img {
    margin-right: 5px;
}


.card.active {
    position: relative;
}


.testimonials .swiper-slide {
    position: relative;
}

.testimonials .swiper-slide {
    position: relative;
}

.testimonials .swiper-slide .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(0,0,0,0.33) 0%, rgba(0,0,0,0.33) 74%, rgba(0,0,0,1) 100%, rgba(0,0,0,1) 100%, rgba(0,0,0,0.33) 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 18px;
    opacity: 0;
    transition: opacity 0.3s ease;
    z-index: 2;
}

.testimonials .swiper-slide-active .overlay {
    opacity: 1;
}

.testimonial-inner {
    z-index: 5;
    position: relative;
}
