.flutter-main-block{
    background-color: #161616;
    padding-top: 25px;
    padding-bottom: 32px;
}

.flutter-inner-block{
    padding: 62px 85px 0;
}

@media screen and (max-width: 600px) {
    .flutter-inner-block{
        padding: 22px 25px 0;
    }
}

.black-inner-title{
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 57px;
    color: #161616;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    margin-bottom: 22px;
}

.black-text{
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    color: #161616;
    padding-left: 10px;
    font-family: 'Poppins', sans-serif;
}

.flutter-white{
    padding: 60px 42px;
    background-color: #FFFFFF;
    height:100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.flutter-dark{
    padding: 60px 42px;
    background-color: #2A2A2A;
    height:100%;
    width: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
}

.icon-wrappers{
    margin-top: auto;
    display: flex;
    align-items: center;
    justify-content: center;
height: 257px;
}



@media screen and (min-width: 900px){
    .icon-wrappers{
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 157px;
    }
}

@media screen and (min-width: 1150px){
    .icon-wrappers{
        margin-top: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 257px;
    }
}


.flutter-icon-team{
    display: flex;
    justify-content: center;
    max-width: 822px;
    margin: 0 auto 30px;
}

.flutter-icon-team img{
    display: block;
    width: 100%;
    height: auto;
}

@media screen and (max-width: 900px) {
    .black-inner-title{
        font-size: 32px;
    }
}