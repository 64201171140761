

.oshbus-case-main {
    height: 815px;
    padding-top: 85px;
    background-color: black;
    overflow: hidden;
}

.oshbus-case-block {
    height: 730px;
    position: relative;
    font-family: 'Poppins', sans-serif;
    background-color: transparent;
}

.oshbus-main-wrapper{
    max-width: 606px;
    margin-top: 210px;
    color:white;
   font-family: 'Poppins', sans-serif;
}

.oshbus-case-title{
    font-size: 58px;
    font-style: normal;
    font-weight: 600;
    line-height: 65px; /* 112.069% */
    letter-spacing: 0.2px;
    margin-bottom: 14px;
}

.oshbus-main-wrapper p{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: 0.2px;
    max-width: 416px;
    margin-bottom: 79px;
}

.changeable-box-oshbus-title{
    color: #EE964B;
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 88.889% */
    letter-spacing: 0.2px;
}

@media screen and (max-width: 1280px){
    .oshbus-case-main {
        height: 790px;
    }

}

@media screen and (max-width: 1100px){
    .oshbus-case-main {
        height: 740px;
    }

    .oshbus-main-wrapper{
        max-width: 606px;
        margin-top: 170px;
    }

}

@media screen and (max-width: 900px){
    .oshbus-case-main {
        height: 680px;
    }

    .oshbus-main-wrapper{
        max-width: 606px;
        margin-top: 120px;
    }
}

@media screen and (max-width: 730px){
    .oshbus-case-main {
        height: 650px;
    }

    .oshbus-main-wrapper{
        max-width: 506px;
        margin-top: 80px;
    }
    .oshbus-case-title{
        font-size: 50px;
        line-height: 65px; /* 112.069% */
    }

}

@media screen and (max-width: 550px){
    .oshbus-case-main {
        height: 650px;
    }

    .oshbus-main-wrapper{
        max-width: 436px;
        margin-top: 50px;
    }
    .oshbus-case-title{
        font-size: 46px;
        line-height: 65px; /* 112.069% */
    }

    .oshbus-main-wrapper p{
        margin-bottom: 49px;
    }


}

@media screen and (max-width: 480px){

    .oshbus-main-wrapper{
        max-width: 386px;
        margin-top: 60px;
    }
    .oshbus-case-title{
        font-size: 36px;
        line-height: 48px; /* 112.069% */
    }

    .oshbus-main-wrapper p{
        margin-bottom: 40px;
    }


}