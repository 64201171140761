.dark-features {
    font-family: 'Poppins', sans-serif;
}

.feature-dark-half {
    height: 830px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.feature-dark-2{
    border-radius: 0px 6px 6px 0px;
    background: #161616;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    max-width: 930px;
    padding: 48px 95px 48px 70px;
    margin-right: -110px;
    z-index: 2;
}

.feature-white-title {
    color: #FFFFFF;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 100%; /* 48px */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.feature-white-text {
    color: white;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}


@media screen and (max-width: 1280px) {

    .feature-white-title {
        font-size: 46px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 24px;
    }

    .feature-white-text {
        font-size: 23px;
    }

    .feature-dark-screen-img-2 {
        margin-left: 60px;
    }

}

@media screen and (max-width: 1120px) {
    .feature-dark-half {
        height: 720px;
    }

    .feature-dark-2 {
        padding: 48px 42px;
    }

    .feature-white-title {
        font-size: 44px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 24px;
    }
}

@media screen and (max-width: 1060px) {
    .feature-dark-half {
        height: 690px;
        position: relative;
    }

    .feature-dark-2 {
        bottom: 24%;
        left: -28px;
        width: 670px;
        padding: 48px 42px;
    }

    .feature-white-title {
        font-size: 42px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 24px;
    }

    .feature-dark-screen-img-2 {
        margin-left: 80px;
    }

}

@media screen and (max-width: 990px) {

    .feature-dark-2{
        padding: 40px 32px;
    }

    .feature-white-title {
        font-size: 38px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 20px;
    }

    .feature-white-text {
        font-size: 20px;
    }


    .feature-dark-screen-img-2 {
        margin-left: 100px;
    }
}

@media screen and (max-width: 990px) {
    .feature-dark-half {
        height: 620px;
    }

    .feature-dark-2 {
        padding: 40px 24px;
    }

    .feature-white-title {
        font-size: 36px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 20px;
    }

    .feature-white-text {
        font-size: 18px;
    }


    .feature-dark-screen-img-2 {
        margin-left: 100px;
        max-width: 190px;
    }
}


.feature-dark-small{
    max-width: 600px;
    padding: 32px 42px;
    border-radius: 6px ;
    background: #2A2A2A;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    margin: 0 auto;

}

@media screen and (max-width: 730px) {
    .feature-dark-half{
        height: 270px;
    }

    .feature-dark-small{
        margin-bottom: -110px;
    }

    .feature-dark-screen-img {
        max-width: 240px;
        margin-left: 0px;
        margin-top: 30px;
    }
}

@media screen and (max-width: 630px) {
    .feature-dark-small{
        margin-bottom: -90px;
    }

    .feature-dark-half {
        height: 270px;
    }

    .feature-dark-screen-img {
        max-width: 240px;
        margin-right: 0px;
        margin-top: 55px;
    }
}

@media screen and (max-width: 500px) {
    .feature-dark-small{
        margin-bottom: -90px;
    }

    .feature-dark-half {
        height: 300px;
    }

    .feature-dark-screen-img {
        max-width: 240px;
        margin-right: 0px;
        margin-top: 55px;
    }
    .feature-dark-screen-img {
        max-width: 230px;
        margin-right: 0px;
        margin-top: 55px;
    }
}



@media screen and (max-width: 450px) {
    .feature-dark-small{
        margin-bottom: -100px;
    }

    .feature-dark-half {
        height: 330px;
    }

    .feature-dark-screen-img {
        max-width: 240px;
        margin-right: 0px;
        margin-top: 55px;
    }

    .feature-dark-screen-img {
        max-width: 230px;
        margin-right: 0px;
        margin-top: 55px;
    }
}