
.client-slider-container{
    /*margin-bottom: 50px;*/
    position: relative;
    z-index: 50;
}

.client-slider-container .swiper_slide{
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;
}

/*.my_swiper2_clients .swiper-wrapper{*/
/*    height: 500px;*/
/*    position: absolute;*/
/*    top: 0;*/
/*    left:0;*/
/*    z-index: 555;*/
/*}*/


.clients-logo-wrapper{
    width: 140px;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    padding: 13px 9px;
    box-sizing: border-box;
    margin: 0 0 10px;
}
.clients-logo-wrapper img{
    width: 95%;
    height: auto;
}

@media screen and (min-width: 360px){
    .clients-logo-wrapper{
        width: 150px;
        height: 70px;
    }
}

@media screen and (min-width: 600px){
    .clients-logo-wrapper{
        width: 160px;
        height: 80px;
    }
}

@media screen and (min-width: 650px){
    .clients-logo-wrapper{
        width: 180px;
        height: 90px;
    }
}

@media screen and (min-width: 720px){
    .clients-logo-wrapper{
        width: 210px;
        height: 90px;
    }
}

@media screen and (min-width: 820px){
    .clients-logo-wrapper{
        width: 215px;
        height: 110px;

    }
}

@media screen and (min-width: 850px){
    .clients-logo-wrapper{
        width: 220px;
        height: 110px;

    }
}

@media screen and (min-width: 930px){
    .clients-logo-wrapper{
        width: 235px;
        height: 120px;

    }
}

@media screen and (min-width: 960px){
    .clients-logo-wrapper{
        width: 240px;
        height: 120px;

    }
}

@media screen and (min-width: 990px){
    .clients-logo-wrapper{
        width: 260px;
        height: 130px;

    }
}

@media screen and (min-width: 1100px){
    .clients-logo-wrapper{
        width: 280px;
        height: 130px;

    }
}

.second-swiper {
    direction: rtl; /* Set the overall direction to right-to-left */
}

.second-swiper .swiper-wrapper {
    display: flex;
    flex-direction: row-reverse; /* Reverse the order of slides */
}

.second-swiper .swiper-slide {
    display: flex;
    flex-direction: row-reverse; /* Reverse the content within each slide */
}