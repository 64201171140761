
.startup-inner {
    min-height: 891px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.startup-hero-section{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: 'Poppins', sans-serif;
    color: white;
}

.startup-hero-section span {
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px; /* 125% */
    letter-spacing: 0.2px;
    margin-bottom: 19px;
    text-align: center;
}

.large-title {
    color: white;
  font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px; /* 84.211% */
    letter-spacing: 0.2px;
    text-align: center;
}

.space-bottom {
    margin-bottom: 85px;
}


.small-subtitles {
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    max-width: 982px;
}

.black-startup-box {
    background-color: #161616;
}

.common-white-text {
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px;
    letter-spacing: 0.2px;
}

.white-startup-box {
    padding: 0 0 48px;
    position: relative;
   min-height: 155px;

}

.white-startup-box-inner{
    position: absolute;
    top: -107px;
    left: 0;
    width: 100%;
    height: 100%;
}

.analytics-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #FFFFFF;
    border-radius: 12px;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    min-height: 282px;
}

.analytics-number {
    color: #161616;
    text-align: center;
    font-size: 102px;
    font-style: normal;
    font-weight: 700;
    line-height: 102px; /* 29.412% */
    letter-spacing: 0.2px;
    margin-bottom: 12px;
}

.analytics-text {
    color: #161616;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 106.667% */
    letter-spacing: 0.2px;
    max-width: 173px;
}



.white-startup-text {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.text-startup-red {
    color: #DD0510
}

@media screen and (max-width: 1080px) {
    .analytics-number {
        font-size: 50px;
        line-height: 50px;
    }
}

@media screen and (max-width: 900px) {


    .space-bottom {
        margin-bottom: 67px;
    }

    .startup-inner {
        min-height: 691px;
    }

    .common-white-text {
        font-size: 20px;
        padding: 0 16px;
    }

    .analytics-number {
        font-size: 56px;
        line-height: 60px;
    }

    .analytics-text {
        font-size: 20px;
        line-height: 30px;
        padding: 0 16px;
    }


}


@media screen and (max-width: 840px) {

    .analytics-number {
        font-size: 36px;
        line-height: 40px;
    }

    .analytics-text {
        font-size: 22px;
        line-height: 25px;
        padding: 0 16px;
    }

}


@media screen and (max-width: 730px) {
    .large-title {
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        text-align: center;
    }

    .space-bottom {
        margin-bottom: 37px;
        padding: 0 16px;
    }

    .small-subtitles {
        font-size: 20px;
    }

    .white-startup-box {
        padding: 28px 0;
        min-height: 125px;
    }


    .analytics-number {
        font-size: 38px;
        line-height: 40px;
    }

    .analytics-text {
        font-size: 16px;
        line-height: 20px;
        padding: 0 16px;
    }

    .analytics-box{
        min-height: 202px;
    }

}


@media screen and (max-width: 600px) {
    .analytics-number {
        font-size: 68px;
        line-height: 68px;
    }

    .analytics-text {
        font-size: 25px;
        line-height: 26px;
        padding: 0 16px;
        max-width: 230px;
    }

    .white-startup-box {
        padding: 28px 0;
        min-height: 695px;
    }

    .white-startup-box-inner{
        position: absolute;
        top: -67px;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .analytics-box {
        margin-bottom: 42px;
        min-height: 222px;

    }

}


@media screen and (max-width: 440px) {
    .large-title {

    }

    .space-bottom {
        margin-bottom: 30px;
        padding: 0 16px;
    }

    .small-subtitles {
        font-size: 18px;
        padding: 0 16px;
    }

    .startup-inner {
        min-height: 701px;
        padding-bottom: 50px;
    }

    .analytics-number {
        font-size: 68px;
        line-height: 68px;
    }

    .analytics-text {
        font-size: 25px;
        line-height: 26px;
        padding: 0 16px;
        max-width: 230px;
    }

    .analytics-box {
      margin-bottom: 42px;
        min-height: 222px;

    }

    .white-startup-box-inner{
        position: absolute;
        top: -37px;
        left: 0;
        width: 100%;
        height: 100%;
    }


}
