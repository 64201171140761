.social-container{
    width: 100%;
    margin-top: 32px;
    background-color: transparent;
    position: absolute;
    top:0;
    left: 0;
}
.social-container .swiper_container{
    width: 100%;
}



.social-container .swiper-slide .swiper-slide-prev{
    width: 88px;
}

.social-container .swiper_container img{
    display: block;
    width: 100%;
    height: auto;
}

@media screen and (min-width: 600px){
    .social-container .swiper_container{
        width: 87%;
    }
}


@media screen and (min-width: 700px){
    .social-container .swiper_container{
        width: 70%;
    }
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.social-container .swiper_slide{
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;
}