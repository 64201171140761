.life-cycle-block {
    background-color: black;
    padding: 62px 0;
}

.life-cycle-title {
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.2px;
    margin-bottom: 24px;

}


/*.cycle-progress-wrapper{*/
/*    max-width: 490px;*/
/*    width: 100%;*/
/*    overflow-x: auto; !* Enable horizontal scrolling *!*/
/*    padding-bottom: 10px;*/
/*}*/

.scroll-progress-bar{
    max-height: 150px;
    overflow-x: auto; /* Enable vertical scrolling */
    display: flex;
    align-items: center;
}

.scroll-progress-item{
    width: 200px;
    height: 100px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.life-cycle-title-wrapper {
    max-width: 1180px;
    margin: 0 auto 96px;
}

.life-cycle-title-wrapper p {
    color: white;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.life-cycle-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 130px;
}



.life-cycle-text {
    color: #6E757C;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
}

.life-cycle-text.active-cycle-text {
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.life-cycle-icons {
    margin-bottom: 22px;
    width: 40px;
    height: 40px;
}

.cycle-progress-box {
    border-radius: 6px;
    background: #202020;
    height: 48px;
    margin-top: 33px;
    display: flex;
    margin-bottom: 60px;
}

.cycle-progress-box-scroll{
    border-radius: 6px;
    background: #202020;
    height: 40px;
    margin-top: 23px;
    display: flex;
    margin-bottom: 48px;
}

.progress-gradient {
    width: 173px;
    border-radius: 6px;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
}

.triangle-up {
    width: 0;
    height: 0;
    border-left: 60px solid transparent;
    border-right: 60px solid transparent;
    border-bottom: 30px solid #202020;
    border-top: 0;
    position: absolute;
    top: -27px;
    left: 6.5%;
}

.cycle-progress {
    color: #fff;
    font-family: 'Poppins', sans-serif;
    background-color: #202020;
    padding: 36px 72px 43px;
    border-radius: 10px;
    position: relative;
}

.book-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: end;
}

.book-btn {
    display: flex;
    max-width: 286px;
    padding: 8px 22px;
    justify-content: center;
    align-items: center;
    gap: 22px;
}

@media screen and (max-width: 900px){
    .life-cycle-title {
        font-size: 50px;
        line-height: 65px;
        margin-bottom: 24px;
    }
    .cycle-progress {
        padding: 30px 52px 40px;
    }
}

@media screen and (max-width: 700px){
    .life-cycle-title {
        font-size: 40px;
        line-height: 55px;
        margin-bottom: 44px;
    }

    .life-cycle-title-wrapper p {
        font-size: 24px;
        line-height: 32px;
        padding: 0 16px;
    }
    .cycle-progress {
        padding: 30px 42px 40px;
    }
}


@media screen and (max-width: 500px){
    .life-cycle-title {
        font-size: 36px;
        line-height: 50px;
        margin-bottom: 30px;
    }

    .life-cycle-title-wrapper p {
        font-size: 20px;
        line-height: 30px;
        padding: 0 16px;
    }

    .cycle-progress {
        padding: 40px 32px 40px;
    }
}
