.sport-horse-block {
    background-color: #000000;
    height: 813px;
    padding-top: 90px;
}

.bg-image-box {
    background-position: left;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-size: 700px;
}

.sport-horse-inner-block {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center;
    height: 100%;

}

.sport-horse-text-wrapper{
    max-width: 682px;
    text-align: right;
}

.sport-horse-inner-block h2 {
    text-align: right;
    font-size: 92px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.sport-horse-inner-block p {
    margin-bottom: 48px;
}



@media screen and (max-width: 1000px){
    .sport-horse-block {
        height: 753px;
        padding-top: 90px;
    }

    .bg-image-box {
        background-size: 590px;
    }
}


@media screen and (max-width: 830px){
    .sport-horse-block {
        height: 670px;
        padding-top: 90px;
    }

    .bg-image-box {
        background-size: 520px;
    }

    .sport-horse-inner-block h2 {
        font-size: 62px;
        line-height: 100%;
    }

    .sport-horse-inner-block p {
        margin-bottom: 48px;
    }
    .sport-horse-text-wrapper{
        max-width: 582px;
        text-align: right;
    }
}

@media screen and (max-width: 730px){
    .sport-horse-block {
        height: 600px;
        padding-top: 90px;
    }

    .bg-image-box {
        background-size: 450px;
    }

    .sport-horse-inner-block h2 {
        font-size: 52px;
        line-height: 100%;
    }

    .sport-horse-inner-block p {
        margin-bottom: 48px;
    }
    .sport-horse-text-wrapper{
        max-width: 482px;
        text-align: right;
    }
}

@media screen and (max-width: 530px){
    .sport-horse-block {
        height: 600px;
        padding-top: 90px;
    }

    .bg-image-box {
        background-size: 350px;
        background-position: bottom left;
    }

    .sport-horse-inner-block {
        display: flex;
        align-items: flex-start;
        height: 100%;
        padding-top: 50px;

    }

}


.dark-changeable-text{
    background-color: #000000;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}


.changeable-box-title{
    color: #B5263E;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 61.538% */
    letter-spacing: 0.2px;
    margin-bottom: 16px;
}

.changeable-box-text{
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px; /* 150% */
    letter-spacing: 0.2px;
    margin-bottom: -50px;
}