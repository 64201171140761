.mobile-dev-wrapper {
    background-color: #2A2A2A;
    min-height: 547px;
    padding-top: 205px;
}

.mobile-dev-pic-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mobile-dev-text-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;

}

.mobile-dev-text-wrapper p {
    max-width: 982px;
}

.black-background{
    background-color: #161616;
    margin-top: -7px;
}