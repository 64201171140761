.project-idea-block {
    padding: 56px 0 72px;
    font-family: 'Poppins', sans-serif;
}

.project-idea-big-box {
    padding: 62px 48px;
    border-radius: 12px;
    background: #161616;
    color: white;
}

.project-idea-big-box h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.2px;
    margin: 0 0 36px 0;
}

.project-idea-big-box p {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px; /* 154.167% */
    letter-spacing: 0.2px;
}

.box-1 {
    position: relative;
    padding: 20px;
    border-radius: 12px;
    background: linear-gradient(90deg, rgba(166, 192, 254, 0.10) 0%, rgba(246, 128, 132, 0.10) 100%);
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
}

.box-1::before {
    content: '';
    position: absolute;
    top: -2px;
    left: -2px;
    right: -2px;
    bottom: -2px;
    background: linear-gradient(90deg, #A6C0FE 0%, #8f5e72 100%);
    z-index: -1;
    border-radius: 14px;

}

.project-box {
    padding: 24px 48px;
    border-radius: 12px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.project-box h3 {
    font-family: 'Poppins', sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.2px;
}

.project-box p {
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.box-1 h3 {
    background: linear-gradient(90deg, #474F63 0%, #7E5868 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.box-1 p {
    color: #DD0510;
}

.box-2 {
    padding: 26px 28px;
    transform: rotate(-4deg);
    border-radius: 12px;
    background: #2A2A2A;
}

.box-2 h3 {
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.box-2 p {
    color: white;
    text-align: center;
    display: inline;
    white-space:nowrap;
}

.box-3 {
    padding: 26px 28px;
    border-radius: 12px;
    background: #2A2A2A;
}

.box-3 p {
    color: white;
}

.box-3 h3 {
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.features-title {
    color:  #161616;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 50px; /* 138.889% */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
    text-align: center;
}


.features-box{
    border-radius: 12px;
    background: linear-gradient(90deg, rgba(166, 192, 254, 0.10) 0%, rgba(246, 128, 132, 0.10) 100%);
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    height: 100%;
    padding: 36px 24px;
}


.result-box{
    border-radius: 12px;
    border: 1px solid #C9C2EC;
    position: relative;
    overflow: hidden;
    height: 100%;
    padding: 27px 24px;
    background:  #161616;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    display: flex;
    flex-direction: column;
    align-items: center;
}


.result-title{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.result-number{
    color:  #DD0510;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 900;
    line-height: 37px;
    letter-spacing: 0.2px;
    margin-bottom: 14px;
    text-align: center;
}

.result-text{
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    letter-spacing: 0.2px;
}

.feature-box{
    color: #000;
    font-family: 'Poppins', sans-serif;
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px; /* 154.167% */
    letter-spacing: 0.2px;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
    height: auto;
}

.feature-box img{
    width: 32px;
    height: 32px;
    margin-right: 8px;
}

@media screen and (max-width: 1200px) {
    .feature-box{
        font-size: 20px;
        line-height: 37px;
    }
    .features-box{
        padding: 36px 12px;
    }
}

@media screen and (max-width: 1160px){
    .box-2 p {
        white-space: normal;
    }
}


@media screen and (max-width: 990px){
    .project-box h3 {
        font-size: 32px;
        line-height: 65px;
    }

    .project-idea-big-box h3 {
        font-size: 52px;
        line-height: 60px;
        margin-bottom: 30px;
    }

    .project-idea-big-box p{
        font-size: 20px;
        line-height: 32px;
    }
}






@media screen and (max-width: 960px) {

    .project-box {
        margin-bottom: 62px;
    }

    .box-1{
        margin-top: 42px;
    }

    .box-3 {
        margin-bottom: 32px;
    }

    .feature-box{
        font-size: 23px;
    }


}


@media screen and (max-width: 560px) {
    .feature-box{
        font-size: 18px;
    }

    .project-idea-big-box h3 {
        font-size: 45px;
        line-height: 50px;
        letter-spacing: 0.2px;
        margin: 0 0 32px 0;
    }
    .project-idea-block {
        padding: 62px 0;
    }

}






