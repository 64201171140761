.step-dark {
    padding: 62px 42px;
    border-radius: 12px;
    background-color: #2A2A2A;
    height:100%;
}

.step-white{
    padding: 62px 42px;
    border-radius: 12px;
    background-color: #FFFFFF;
    height:100%;
}

.step-white-title {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 112.903% */
    letter-spacing: 0.2px;
    margin-bottom: 42px
}

.step-white-text {
    color: #FFF;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
}

.step-black-title {
    color: #161616;
    font-family: 'Poppins', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 112.903% */
    letter-spacing: 0.2px;
    margin-bottom: 42px
}

.step-black-text {
    color: #161616;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
}

.step-gradient-title{
    font-family: 'Poppins', sans-serif;
    font-size: 62px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px; /* 112.903% */
    letter-spacing: 0.2px;
    background: linear-gradient(90deg, #A6C0FE 0%, #F68084 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 42px;
}

.step-img-box{
    width: 100%;
    height: 100%;
    border-radius: 12px;
    min-height: 527px;
}

@media screen and (max-width: 800px){
    .step-gradient-title{
        font-size: 36px;
        line-height: 40px; /* 112.903% */
        margin-bottom: 20px;
    }

    .step-black-title {
        font-size: 36px;
        line-height: 40px; /* 112.903% */
        margin-bottom: 20px;
    }

    .step-white-title{
        font-size: 36px;
        line-height: 40px; /* 112.903% */
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 600px){
    .step-gradient-title{
        font-size: 30px;
        line-height: 40px; /* 112.903% */
    }
    .step-white-text {
        font-size: 20px;
    }

    .step-black-text {
        font-size: 20px;
    }

    .step-dark {
        padding: 32px;

    }

    .step-white{
        padding: 32px;
    }
}