.black-box {
    border-radius: 4px;
    background: #2A2A2A;
    box-shadow: 17px 17px 0 0 #CB2C23;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 102%;
    padding: 43px 75px;
    margin-right: 16px;
    min-height: 315px;
}

.text-change-block-title {
    font-family: 'Poppins', sans-serif;
    color: #848484;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    text-align: left;
    cursor: pointer;
}


.text-change-block-title-ux{
    font-family: 'Poppins', sans-serif;
    color: #848484;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px; /* 133.333% */
    letter-spacing: 0.2px;
    text-align: left;
    cursor: pointer;
}

.text-change-block-parent-ux{
    height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media screen and (max-width: 600px) {
    .text-change-block-title{
        font-size: 20px;
    }
    .text-change-block-title-ux{
        font-size: 20px;
    }
    .black-box{
        padding: 23px 35px;
    }
}

@media screen and (max-width: 900px) {
    .text-change-block-parent-ux{
        height: 205px;
    }
}

.text-change-block-title:hover{
    color: #CB2C23;
}

.text-change-block-parent{
    min-height: 265px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.text-change-block{
    padding: 10px 0 10px 22px;
    position: relative;
}

.text-change-block-ux{
    padding: 10px 0 10px 22px;
    position: relative;
}


.red-border{
    background-color:  #DD0510;
    border-radius: 4px;
    width: 4px;
    height: 100%;
    position: absolute;
    left: -4px;
    top: 0;
}

.red-text{
    color: #DD0510;
}

.white-text{
    color: #FFFFFF;
}

.border-div{
    border-radius: 4px;
    background-color:  #C4C4C4;
    height: 100%;
    width: 4px;
}