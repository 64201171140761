.our-expertise-block {
    background-color: #FFFFFF;
    padding: 62px 0 0;
    border-radius: 12px;
}

.expertise-box {
    height: 326px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    padding: 30px 50px;
    box-shadow: 2px 4px 12px 0 rgba(0, 0, 0, 0.08);
}

.expertise-box h2 {
    max-width: 384px;
    font-size: 30px;
}

@media screen and (min-width: 1000px) {
    .expertise-box h2 {
       font-size: 38px;
        margin-bottom: 20px;
    }
}

.dark-grey {
    background-color: #2A2A2A;
}

.black {
    background-color: #161616;
}

.expertise-grid-box {
    margin: 62px 85px 0;
}

@media screen and (max-width: 1000px) {
    .expertise-grid-box {
        margin: 62px 25px 0;
    }
}

.expertise-img-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    max-height: 140px;
    height: 100%;
}

.expertise-img-wrapper img {
    width: 100%;
    height: auto;
    display: block;
}

@media screen and (max-width: 800px){
    .expertise-img-wrapper img {
        width: 80%;
        height: auto;
        display: block;
    }
}

.last-box {
    background: #F5F5F7;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    padding: 42px 0 40px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 326px;
}

.last-box h2{
    margin-bottom: auto;
}