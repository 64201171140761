
.ux-block{
    height: 962px;
    overflow: hidden;
}

@media screen and (max-width: 800px) {
    .ux-block{
         height: 632px;
    }
}

.overlay-ux{
    padding-top: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: inherit;
}

.ux-main-title{
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    line-height: 68px;
    color: #FFFFFF;
    text-align: center;
}

.ux-title-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 984px;
    width: 100%;
    margin: 0 auto;
}

.ux-white-box{
    padding: 30px 0 62px 0;
    text-align: center;
}

.ux-white-box h2{
    margin-bottom: 22px;
}

.ux-benefit-title{
    color: #161616;
    font-family: 'Poppins', sans-serif;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 48px; /* 126.316% */
    letter-spacing: 0.2px;
    text-align: center;
}

.why-us-box{
  text-align: center;
    max-width: 992px;
    width: 100%;
    margin: 0 auto;
    padding: 0 0 62px;
}

.why-us-box h2{
    margin: -10px auto 10px auto;
}

@media screen and (max-width: 600px){
    .ux-main-title{
        font-size: 28px;
        line-height: 40px;
    }

    .ux-block{
        height: 532px;
    }

    .ux-benefit-title{
        font-size: 30px;
    }
}
