.footer{
    background: #F2F2F2;
    caret-color: transparent;
    padding-bottom: 10px;
}
.footer-social{
    background-color: transparent;
    padding: 32px 0;
    border-bottom: 1px solid #DEDEDE;
}
.footer-social p {
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    color: #161616;
    text-align: center;
    margin-bottom: 12px;
}

.social-links{
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-links img{
    margin: 0 5px;
}
.footer-page-box{
    padding: 30px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #DEDEDE;
    flex-wrap: wrap;
}

.footer-page-link{
    font-family: 'Poppins', sans-serif;
    font-size: 16px;
    color: #161616;
    border: none;
    background-color: transparent;
    padding: 2px 5px;
}

.footer-last-box{
    display: flex;
    justify-content: center;
    padding: 32px 5px;
    flex-wrap: wrap;
}


.footer-last-box p{
    font-family: 'Poppins', sans-serif;
    font-size: 14px;
    font-weight: 300;
    color: #848484;
    margin: 0 10px;
}
@media screen and (min-width: 700px) {
    .footer-logo-link{
        font-size: 14px;
        width: 55%;
    }
}

