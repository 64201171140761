.gettik-review-block {
    background: #373736;
    padding: 76px 0;
    position: relative;
    height: 745px;
    overflow: hidden;
}

.gettik-review-inner-block {
    border-radius: 20px;
    border: 1px solid #353A3F;
    background: #1F1F1F;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    padding: 48px 125px;
    position: relative;
    z-index: 3;
}

.arrow-up {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 106px;
    padding: 0 0 0 22px;
    margin-bottom: 28px;
}

.gettik-review-inner-block h2 {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 67px; /* 139.583% */
    letter-spacing: 0.2px;
    margin-bottom: 44px;
}

.gettik-review-inner-block p {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 32px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px; /* 131.25% */
    letter-spacing: 0.2px;
}

.gettik-review-triangle {
    background: linear-gradient(270deg, #1D1D1D -22.78%, #333 113.24%);
    width: 100vw;
    height: 1700px;
    position: absolute;
    top: 16%;
    left: 64%;
    transform: rotate(137deg);
    z-index: 2;
}

.flag-uk {
    margin-right: 14px;
}


.flag-wrapper-box {
    display: flex;
    align-items: center;
    margin-top: 36px;
}

.flag-wrapper-box p {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 37px; /* 154.167% */
    letter-spacing: 0.2px;

}

.flag-wrapper-box span {
    color: #6C6C6C;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; /* 133.333% */
    letter-spacing: 0.2px;
}

.avatar-wrapper {
    max-width: 259px;
    max-height: 261px;
    position: relative;
    overflow: hidden;
}

.avatar-wrapper-small{
    width: 159px;
    height: 161px;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
  float: right;
    margin-left: 10px;
}

.avatar-wrapper-small img{
    width: 100%;
    height: auto;
    object-fit: cover;

}

.avatar-img-wrapper {
    background-color: transparent;
   padding-top: 16px;
}


.gettik-review-polygon1{
    position: absolute;
    bottom: 32px;
    right: 28%;
    z-index: 3;
}

.gettik-review-polygon{
    position: absolute;
    bottom: 32px;
    right: 16%;
    z-index: 3;
}


@media screen and (max-width: 1280px){
    .gettik-review-triangle{
        width: 123vw;
        height: 1784px;
    }

    .gettik-review-block {
        padding: 75px 0;
        height: 705px;
    }

    .gettik-review-inner-block {
        padding: 48px 83px;
    }

    .gettik-review-inner-block h2 {
        font-size: 43px;
        line-height: 63px; /* 139.583% */
        margin-bottom: 24px;
    }

    .gettik-review-inner-block p {
        font-size: 28px;
        line-height: 40px;
    }

}

@media screen and (max-width: 1060px){
    .gettik-review-block {
        padding: 65px 0;
        height: 740px;
    }

    .gettik-review-inner-block {
        padding: 48px 83px;
    }

    .arrow-up{
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 1000px){

    .gettik-review-inner-block p {
        font-size: 25px;
        line-height: 38px;
    }

    .gettik-review-block {
        padding: 65px 0;
        height: 720px;
    }
}

@media screen and (max-width: 900px){

    .gettik-review-inner-block {
        padding: 48px 53px;
    }

    .gettik-review-inner-block p {
        font-size: 24px;
        line-height: 36px;
    }

    .gettik-review-inner-block h2 {
        font-size: 38px;
        line-height: 53px; /* 139.583% */
        margin-bottom: 20px;
    }

    .gettik-review-triangle{
        width: 153vw;
        height: 1784px;
    }
}


@media screen and (max-width: 820px){

    .gettik-review-inner-block {
        padding: 38px 33px;
    }

    .gettik-review-inner-block p {
        font-size: 22px;
        line-height: 34px;
    }

    .gettik-review-inner-block h2 {
        font-size: 38px;
        line-height: 53px; /* 139.583% */
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 730px){

    .gettik-review-inner-block p {
        font-size: 25px;
        line-height: 38px;
    }

    .gettik-review-block {
        padding: 45px 0;
        height: 510px;
    }

    .gettik-review-inner-block p {
        font-size: 20px;
        line-height: 30px;
    }

    .gettik-review-inner-block h2 {
        font-size: 32px;
        line-height: 45px; /* 139.583% */
        margin-bottom: 20px;
    }

    .gettik-review-triangle{
        width: 100vw;
        height: 1700px;
    }

    .flag-wrapper-box {
        display: flex;
        align-items: center;
        margin-top: 0;
    }

    .gettik-review-inner-block h2 {
        font-size: 42px;
        line-height: 53px; /* 139.583% */
        margin-bottom: 20px;
    }

}


@media screen and (max-width: 660px){

    .gettik-review-block {
        padding: 45px 0;
        height: 580px;
    }
}

@media screen and (max-width: 580px){

    .gettik-review-block {
        padding: 45px 0;
        height: 590px;
    }
}

@media screen and (max-width: 510px){
    .gettik-review-inner-block p {
        font-size: 18px;
        line-height: 30px;
    }

    .gettik-review-inner-block {
        padding: 28px 23px;
    }

    .avatar-social-links{
        margin-top: 20px;
    }

    .gettik-review-inner-block h2 {
        font-size: 36px;
        line-height: 50px; /* 139.583% */
        margin-bottom: 20px;
    }

}

@media screen and (max-width: 430px){

    .gettik-review-block {
        padding: 45px 0;
        height: 620px;
    }
}

@media screen and (max-width: 400px){
    .gettik-review-block {
        padding: 25px 0;
        height: 610px;
    }
}

@media screen and (max-width: 390px){
    .gettik-review-block {
        padding: 25px 0;
        height: 610px;
    }
}
