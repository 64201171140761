
.gettik-block {
    padding-bottom: 112px;
}

.delivery {
    min-height: 344px;
    background-color: white;
    padding-top: 71px;
}

.startup-delivery-title {
    font-family: 'Poppins', sans-serif;
    font-size: 64px;
    font-style: normal;
    font-weight: 700;
    line-height: 70px;
    letter-spacing: 0.2px;
    margin: 0;
}

.delivery p {
    color: #161616;
    font-family: 'Poppins', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: 0.2px;
}

.gettik-inner {
    height: 705px;
    overflow: visible;
    position: relative;
    padding-top: 112px;
    display: flex;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.gettik-imgs {
    position: absolute;
    bottom: -140px;
    right: 0;
}

.gettik-title {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 72px;
    font-style: normal;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 0.2px;
    margin: 0;
}

.gettik-title-wrapper {
    margin-bottom: 36px;
}

.country-wrapper {
    display: flex;
    align-items: center;
    padding-left: 70px;
}

.country-wrapper span {
    color: white;
    font-family: 'Poppins', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 67px;
    letter-spacing: 0.2px;
}

.gettik-title-wrapper img {
    display: inline-block;
    width: 37px;
    height: auto;
    margin-right: 15px;
}


.platform-box {
    width: 130px;
    color: white;
    font-family: 'Poppins', sans-serif;
}

.platform-box h4 {
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 34px;
    letter-spacing: 0.2px;
}

.platform-box p {
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-style: normal;
    font-weight: 300;
    line-height: 34px;
    letter-spacing: 0.2px;
}

.see-btn {
    border-radius: 6px;
    background:  #DD0510;
    position: absolute;
    bottom: 75px;
    left: 0;
    cursor: pointer;
    max-width: 286px;
    width: 100%;
    color: #FFFFFF;
}

.see-btn-mobile {
    position: absolute;
    bottom: 55px;
    left: 31%;
}

@media screen and (max-width: 1200px) {
    .gettik-inner {
        height: 605px;
        overflow: visible;
        position: relative;
        padding-top: 112px;
        display: flex;
    }

    .gettik-imgs {
        position: absolute;
        bottom: -140px;
        right: 0;
        width: 58%;
    }

    .startup-delivery-title {
        font-size: 56px;
        line-height: 58px;
        margin-bottom: 45px;
    }
}

@media screen and (max-width: 1100px) {
    .gettik-inner {
        height: 505px;
        overflow: visible;
        position: relative;
        padding-top: 92px;
        display: flex;
    }

    .gettik-imgs {
        position: absolute;
        bottom: -126px;
        right: 0;
        width: 55%;
    }


    .startup-delivery-title {
        font-size: 54px;
        line-height: 70px;
    }
    .startup-delivery-title {
        font-size: 46px;
        line-height: 55px;
        margin-bottom: 45px;
    }
}

@media screen and (max-width: 960px) {
    .gettik-inner {
        height: 505px;
        overflow: visible;
        position: relative;
        padding-top: 92px;
        display: flex;
    }

    .gettik-imgs {
        position: absolute;
        bottom: -55px;
        right: 0;
        width: 50%;
    }


    .startup-delivery-title {
        font-size: 50px;
        line-height: 60px;
        margin-bottom: 45px;
    }

}


@media screen and (max-width: 860px) {
    .gettik-inner {
        height: 505px;
        overflow: visible;
        position: relative;
        padding-top: 92px;
        display: flex;
    }

    .gettik-imgs {
        position: absolute;
        bottom: -55px;
        right: 0;
        width: 55%;
    }


    .gettik-title {
        font-size: 52px;
        line-height: 50px;
    }

    .country-wrapper span {
        font-size: 16px;
        line-height: 47px;
        letter-spacing: 0.2px;
    }

    .country-wrapper {
        display: flex;
        align-items: center;
        padding-left: 30px;
    }

    .gettik-block {
        padding-bottom: 42px;
    }

}


@media screen and (max-width: 800px) {
    .gettik-inner {
        height: 505px;
        overflow: visible;
        position: relative;
        padding-top: 72px;
        display: flex;
    }


    .gettik-imgs {
        position: absolute;
        bottom: -48px;
        right: 0;
        width: 63%;
    }

    .delivery{
        padding-top: 50px;
        padding-bottom: 62px;
    }

}


@media screen and (max-width: 700px) {
    .gettik-inner {
        height: 1065px;
        overflow: visible;
        position: relative;
        padding-top: 82px;
        padding-bottom: 82px;
        display: flex;
        flex-direction: column;
    }

    .gettik-title-wrapper {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .gettik-inner {
        height: 1005px;
        overflow: visible;
        position: relative;
        padding-top: 40px;
        display: flex;
    }

    .gettik-title-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .gettik-title {
        font-size: 62px;
        line-height: 50px;
    }

    .country-wrapper span {
        font-size: 20px;
        line-height: 47px;
        letter-spacing: 0.2px;
    }

    .country-wrapper {
        display: flex;
        align-items: center;
        padding-left: 30px;
    }
}


@media screen and (max-width: 580px) {
    .gettik-title-wrapper {
        margin-bottom: 40px;
    }

    .gettik-inner {
        height: 1115px;
    }

    .gettik-block {
        padding-bottom: 2px;
    }

}


@media screen and (max-width: 560px) {
    .gettik-inner {
        height: 1070px;
    }
}

@media screen and (max-width: 530px) {
    .gettik-inner {
        height: 1050px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 29%;
    }
}

@media screen and (max-width: 510px) {
    .gettik-inner {
        height: 1030px;
    }
}

@media screen and (max-width: 490px) {
    .gettik-inner {
        height: 1010px;
    }
}


@media screen and (max-width: 460px) {
    .gettik-inner {
        height: 970px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 27%;
    }
}

@media screen and (max-width: 440px) {
    .gettik-inner {
        height: 950px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 25%;
    }
}

@media screen and (max-width: 420px) {
    .gettik-inner {
        height: 930px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 24%;
    }
}

@media screen and (max-width: 400px) {
    .gettik-inner {
        height: 910px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 24%;
    }
}

@media screen and (max-width: 380px) {
    .gettik-inner {
        height: 880px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 22%;
    }
}

@media screen and (max-width: 370px) {
    .gettik-inner {
        height: 860px;
    }

    .see-btn-mobile {
        position: absolute;
        bottom: 55px;
        left: 22%;
    }
}