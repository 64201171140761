.container{
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
}


.container .swiper-slide .swiper-slide-prev{
    width: 88px;
}

.container .swiper_container{
    width: 100%;
}

@media screen and (min-width: 600px){
    .container .swiper_container{
        width: 87%;
    }
}


@media screen and (min-width: 700px){
    .container .swiper_container{
        width: 70%;
    }
}

.container .swiper_container img{
    display: block;
    width: 100%;
    height: auto;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
    display: none;
}

.container .swiper_slide{
display: flex;
    justify-content: center;
    background-position: center;
    background-size: contain;
}