.project-screens-block {
    min-height: 761px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.screens-wrapper {
    max-width: 925px;
    margin: auto auto;
    display: flex;
    justify-content: space-between;
}

.screens-wrapper img {
    width: 100%;
    max-width: 209px;
    height: auto;
    display: block;
}

.available-on-white {
    font-size: 30px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}

.info-box-white {
    display: flex;
    align-items: center;
}

.info-box-white img {
    margin-right: 14px;
}

.info-box-white p {
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


.play-market-wrapper {
    position: absolute;
    bottom: -23%;
    left: 1%;
}


@media screen and (max-width: 880px) {
    .project-screens-block {
        min-height: 680px;
    }

    .screens-wrapper {
        max-width: 825px;
    }

    .screens-wrapper img {
        max-width: 190px;
    }
}


@media screen and (max-width: 880px) {
    .project-screens-block {
        min-height: 650px;
    }

    .screens-wrapper {
        max-width: 725px;
    }

    .screens-wrapper img {
        max-width: 170px;
    }
    .info-box-white p {
        font-size: 24px;
    }
    .available-on-white {
        font-size: 24px;
    }

}

@media screen and (max-width: 750px) {
    .project-screens-block {
        min-height: 630px;
    }

    .screens-wrapper {
        max-width: 700px;
    }

    .screens-wrapper img {
        max-width: 160px;
    }
    .info-box-white p {
        font-size: 24px;
    }
    .available-on-white {
        font-size: 24px;
    }

}


.play-market-wrapper-small{
    padding: 20px 0 80px;
}