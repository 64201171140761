.oshbus-market {
    height: 705px;
    background-color: #EE964B;
    color: white;
    font-family: 'Poppins', sans-serif;
}


.oshbus-logo-wrapper {
    margin-bottom: 24px;
}

.oshbus-store-wrapper {
    align-self: center;
}

.oshbus-available-on {
    color: white;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'Poppins', sans-serif;
}

.oshbus-store-text {
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: #FFFFFF;
    font-family: 'Poppins', sans-serif;
}


.oshbus-slider-box{
    width: 100%;
    height: 100%;
    background-color: #EE964B;
    padding-top: 62px;
}

.oshbus-market-text-wrapper{
    padding: 0 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


@media screen and (max-width: 1280px) {
    .oshbus-market {
        height: 605px;
    }

    .oshbus-available-on {
        font-size: 32px;
    }

    .oshbus-store-text {
        font-size: 32px;
    }
}

@media screen and (max-width: 1100px) {
    .oshbus-market {
        height: 505px;
    }
}

@media screen and (max-width: 950px) {
    .oshbus-market {
        height: 485px;
    }

    .oshbus-available-on {
        font-size: 30px;
    }

    .oshbus-store-text {
        font-size: 30px;
    }
}

@media screen and (max-width: 950px) {
    .oshbus-market {
        height: 485px;
    }

    .oshbus-available-on {
        font-size: 30px;
    }

    .oshbus-store-text {
        font-size: 30px;
    }
}

@media screen and (max-width: 920px) {
    .oshbus-market {
        height: 405px;
    }

    .oshbus-available-on {
        font-size: 28px;
    }

    .oshbus-store-text {
        font-size: 28px;
    }

    .oshbus-logo-wrapper {
        margin-bottom: 18px;
    }

    .oshbus-logo-wrapper img {
        width: 45%;
        height: auto;
    }
}

@media screen and (max-width: 810px) {
    .oshbus-market {
        height: 355px;
    }

    .oshbus-available-on {
        font-size: 24px;
    }

    .oshbus-store-text {
        font-size: 24px;
    }

    .oshbus-logo-wrapper {
        margin-bottom: -10px;
    }


}


@media screen and (max-width: 730px) {

    .oshbus-available-on {
        font-size: 28px;
    }

    .oshbus-store-text {
        font-size: 28px;
    }


    .oshbus-logo {
        width: 100%;
        height: auto;
        max-width: 130px;
    }

    .oshbus-market-text-wrapper{
        margin-top: -50px;
    }
}

@media screen and (max-width: 680px) {

    .oshbus-market-text-wrapper{
        margin-top: -80px;
    }
}

@media screen and (max-width: 650px) {

    .oshbus-market-text-wrapper{
        margin-top: -90px;
    }
}

@media screen and (max-width: 620px) {

    .oshbus-market-text-wrapper{
        margin-top: -130px;
    }
}

@media screen and (max-width: 600px) {

    .oshbus-market-text-wrapper{
        margin-top: -50px;
    }
    .oshbus-available-on {
        font-size: 22px;
    }

    .oshbus-store-text {
        font-size: 22px;
    }
}

@media screen and (max-width: 550px) {

    .oshbus-market-text-wrapper{
        margin-top: -90px;
    }


}

@media screen and (max-width: 520px) {

    .oshbus-available-on {
        font-size: 24px;
        text-align: center;
    }

    .oshbus-store-text {
        font-size: 24px;
    }
    .oshbus-market-text-wrapper{
        margin-top: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}

@media screen and (max-width: 500px) {

    .oshbus-market-text-wrapper{
        margin-top: -30px;
    }
}

@media screen and (max-width: 440px) {

    .oshbus-market-text-wrapper{
        margin-top: 10px;
    }
}

@media screen and (max-width: 410px) {

    .oshbus-market-text-wrapper{
        margin-top: -20px;
    }

    .oshbus-available-on {
        font-size: 20px;
    }

    .oshbus-store-text {
        font-size: 20px;
    }
}


@media screen and (max-width: 390px) {

    .oshbus-market-text-wrapper{
        margin-top: -10px;
    }
}