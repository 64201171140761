
.feature-white-box {
    border-radius: 6px 0px 0px 6px;
    background: white;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    max-width: 930px;
    padding: 48px 105px;
    margin-left: -110px;
}

.feature-white-small{
    max-width: 600px;
    padding: 32px 42px;
    border-radius: 6px ;
    background: white;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    margin: 0 auto;

}

@media screen and (max-width: 1120px) {
    .feature-white-box {
        padding: 48px 82px;
    }
}

@media screen and (max-width: 1060px) {
    .feature-white-box {
        bottom: 24%;
        left: -28px;
        width: 670px;
        padding: 48px 72px;
    }
}

@media screen and (max-width: 990px) {
    .feature-white-box {
        padding: 40px 62px;
    }
}



@media screen and (max-width: 730px) {
    .feature-dark-half{
        height: 270px;
    }

    .feature-white-small{
        margin-bottom: -110px;
    }

    .feature-white-box {
        padding: 40px 62px;
        border-radius: 6px;
    }
}

@media screen and (max-width: 630px) {
    .feature-white-small{
        margin-bottom: -90px;
    }

}

@media screen and (max-width: 500px) {
    .feature-white-small{
        margin-bottom: -90px;
    }

}



@media screen and (max-width: 450px) {
    .feature-white-small{
        margin-bottom: -100px;
    }
}