.features{
    padding: 62px 0 0;
    font-family: 'Poppins', sans-serif;
}

.feature-white-half{
    height: 920px;
    position: relative;
}

.feature-white{
    border-radius: 0px 6px 6px 0px;
    background: white;
    box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.08);
    position: absolute;
    bottom: 24%;
    left: -28px;
    width: 860px;
    padding: 48px 102px;
}


.sporthorse-feature-main-title{
    color: #161616;
    font-size: 52px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px; /* 100% */
    letter-spacing: 0.2px;
    margin-top: 100px;
    padding-left: 75px;
    padding-right: 10px;
}

.feature-black-title{
    color:  #161616;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 40px; /* 83.333% */
    letter-spacing: 0.2px;
    margin-bottom: 24px;
}

.feature-black-text{
    color: #161616;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.feature-screen-img{
    margin-top:350px;
    margin-left: 130px;
}

@media screen and (max-width: 1280px){
    .feature-white-half{
        height: 880px;
        position: relative;
    }

    .feature-white{
        bottom: 24%;
        left: -28px;
        width: 720px;
        padding: 48px 82px;
    }

    .feature-black-title{
        font-size: 46px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 24px;
    }

    .feature-black-text{
        font-size: 23px;
    }

    .feature-screen-img{
        margin-top: 280px;
        margin-left: 130px;
    }

}

@media screen and (max-width: 1120px){
    .feature-white-half{
        height: 860px;
        position: relative;
    }

    .feature-white{
        bottom: 24%;
        left: -28px;
        width: 680px;
        padding: 48px 82px;
    }

    .feature-black-title{
        font-size: 44px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 24px;
    }

}

@media screen and (max-width: 1060px){
    .feature-white-half{
        height: 790px;
        position: relative;
    }

    .feature-white{
        bottom: 24%;
        left: -28px;
        width: 670px;
        padding: 48px 72px;
    }

    .feature-black-title{
        font-size: 42px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 24px;
    }

    .sporthorse-feature-main-title{
        font-size: 47px;
        line-height: 52px; /* 100% */
        margin-top: 100px;
        padding-left: 55px;
    }

    .feature-screen-img{
        margin-top: 230px;
        margin-left: 130px;
    }

}

@media screen and (max-width: 990px) {
    .feature-white-half{
        height: 720px;
        position: relative;
    }

    .feature-white{
        bottom: 27%;
        left: -28px;
        width: 630px;
        padding: 40px 62px;
    }

    .feature-black-title{
        font-size: 38px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 20px;
    }

    .sporthorse-feature-main-title{
        font-size: 42px;
        line-height: 48px; /* 100% */
        margin-top: 90px;
        padding-left: 35px;
    }

    .feature-black-text{
        font-size: 20px;
    }

    .feature-screen-img{
        margin-top: 150px;
        margin-left: 130px;
    }
}

@media screen and (max-width: 930px) {
    .feature-white{
        bottom: 27%;
        left: -28px;
        width: 600px;
        padding: 40px 32px 40px 52px;
    }
}

@media screen and (max-width: 900px) {
    .feature-white{
        bottom: 27%;
        left: -28px;
        width: 580px;
        padding: 40px 32px 40px 52px;
    }
}

@media screen and (max-width: 870px) {
    .feature-white{
        bottom: 23%;
        left: -28px;
        width: 560px;
        padding: 40px 32px 40px 52px;
    }

    .feature-white-half{
        height: 600px;
        position: relative;
    }

   .sporthorse-feature-main-title{
        font-size: 36px;
        line-height: 46px; /* 100% */
        margin-top: 80px;
        padding-left: 25px;
    }

    .feature-black-text{
        font-size: 18px;
    }

    .feature-black-title{
        font-size: 34px;
        line-height: 40px; /* 83.333% */
        margin-bottom: 14px;
    }
}

@media screen and (max-width: 820px) {
    .feature-white {
        bottom: 23%;
        left: -28px;
        width: 550px;
        padding: 32px 32px 32px 52px;
    }

    .feature-screen-img{
        margin-top: 230px;
        margin-left: 130px;
        max-width: 190px;
    }
}

@media screen and (max-width: 770px) {
    .feature-white {
        bottom: 23%;
        left: -28px;
        width: 520px;
        padding: 32px 32px 32px 52px;
    }

    .feature-screen-img{
        margin-top: 120px;
        margin-left: 130px;
        max-width: 190px;
    }
    .feature-white-half{
        height: 580px;
        position: relative;
    }
}

@media screen and (max-width: 730px) {
    .sporthorse-feature-main-title{
        font-size: 36px;
        line-height: 46px; /* 100% */
        margin-top: 0;
        padding-left: 25px;
        margin-bottom: 30px;
        text-align: center;
    }

    .feature-white {
       position: unset;
        width: 520px;
        margin: 0 auto;
        padding: 32px 32px 32px 52px;
        border-radius: 6px;
    }

    .feature-screen-img{
        margin-top: 100px;
        margin-left: 0px;
        margin-bottom: 40px;
        max-width: 240px;
    }

    .feature-white-half{
        height: 300px;
        position: relative;
    }

}

@media screen and (max-width: 580px) {
    .feature-white {
        position: unset;
        max-width: 470px;
        margin: 0 auto;
        padding: 26px 26px 26px 32px;
    }
    .feature-screen-img{
        margin-top: 115px;
        margin-left: 0px;
        margin-bottom: 50px;
        max-width: 240px;
    }
}

@media screen and (max-width: 530px) {
    .feature-white {
        position: unset;
        max-width: 450px;
        margin: 0 auto;
        padding: 26px 26px 26px 32px;
    }
    .feature-screen-img{
        margin-top: 125px;
        margin-left: 0px;
        margin-bottom: 50px;
        max-width: 230px;
    }

    .sporthorse-feature-main-title{
        font-size: 32px;
        line-height: 40px; /* 100% */
        margin-top: -10px;
        margin-bottom: 35px;
        text-align: center;
    }
}


@media screen and (max-width: 490px) {
    .feature-white {
        position: unset;
        max-width: 400px;
        margin: 0 auto;
        padding: 26px 26px 26px 32px;
    }

    .feature-screen-img{
        margin-top: 125px;
        margin-left: 0px;
        margin-bottom: 50px;
        max-width: 230px;
    }

    .feature-black-text{
        font-size: 16px;
    }

    .feature-black-title{
        font-size: 28px;
        line-height: 38px; /* 83.333% */
        margin-bottom: 14px;
    }
}

@media screen and (max-width: 430px) {
    .feature-white {
        position: unset;
        max-width: 350px;
        margin: 0 auto;
        padding: 20px 20px;
    }

}

@media screen and (max-width: 390px) {
    .feature-white {
        position: unset;
        max-width: 320px;
        margin: 0 auto;
        padding: 20px 20px;
    }

}